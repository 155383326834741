import React, { useState } from "react";
import { DatePicker } from "antd";
import AppButton from "../AppButton/AppButton";
import moment from "moment";
import { Fragment } from "react";

import "./DashboardFilter.scss";

export default function DashboardFilter(props: {
    labels?: string[];
    onClick?: CallableFunction; selectedIndex?: number; showCustom?: boolean;
}) {

    const { RangePicker } = DatePicker;

    const [fromDate, setFromDate] = useState(moment("2/18/2021", "d/mm/yyyy"));
    const [toDate, setToDate] = useState(moment());

    return (
        <div className="dashboard-filter-wrap">
            <div style={{ justifyContent: "flex-end" }} className={"row m-0 p-0"}>
                {props.labels?.map((lbl, i) => {
                    return (
                        <div onClick={() => { props.onClick?.(i); }} key={i} className="col-auto filter-padding">
                            <div className={i == props.selectedIndex ? "condo-category active" : "condo-category"}>
                                {lbl}
                            </div>
                        </div>
                    );
                })}
                {props?.showCustom == false
                    ? (<Fragment />)
                    : (<div onClick={() => { props.onClick?.(99); }} className="col-auto filter-padding">
                        <div className={99 == props.selectedIndex ? "condo-category active" : "condo-category"}>
                            Custom
                        </div>
                    </div>)
                }
            </div>
            <div className={props.selectedIndex == 99 ? "custom-picker open" : "custom-picker"}>
                <RangePicker value={[fromDate, toDate]} onChange={(val: any) => { setFromDate(val?.[0]); setToDate(val?.[1]); }}
                    picker="month" />
                <AppButton onClick={() => { props.onClick?.(99, fromDate, toDate); }} className={"filter-button"}>
                    Filter
                </AppButton>
            </div>
        </div>
    );
}
