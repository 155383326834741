import { Box, Modal } from "@material-ui/core";
import TickCircle from "../../components/TickCircle/TickCircle";

interface ModalProps {
  modalIsOpen: boolean;
  selectedImage: any;
  onCloseModal: () => void;
}

export default function SupportTicketImageModal({
  modalIsOpen,
  selectedImage,
  onCloseModal,
}: ModalProps) {
  return (
    <Modal
      open={modalIsOpen}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box style={{ display: "flex" }}>
        <img src={selectedImage} width={1000} height={600} />
        <div
          onClick={() => onCloseModal()}
          style={{ cursor: "pointer", height: 2, alignItems: "center" }}
        >
          <TickCircle icon="close" large />
        </div>
      </Box>
    </Modal>
  );
}
