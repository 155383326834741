import React, {useState} from "react";
import './styles.scss'

export default function AppPasswordField(props: { setter?, value?, placeholder? }) {
  const [showPw, setShowPw] = useState(false);
  return (
    <div className={'app-pw-wrap'}>
      <input
        type={showPw ? "text" : "password"}
        onChange={(e) => {
          props.setter(e.target.value);
        }}
        value={props.value}
        placeholder={props.placeholder}
      />
      <div className={'icon'} onClick={() => {
        setShowPw(!showPw)
      }}>
        {showPw ?
          <i className="fa fa-eye" aria-hidden="true"></i>
          :
          <i className="fa fa-eye-slash" aria-hidden="true"></i>
        }
      </div>
    </div>
  )
}