import React, { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppButton from "../../components/AppButton/AppButton";
import AppLoading from "../../components/AppLoading/AppLoading";
import Dot from "../../components/Dot/Dot";
import Navbar from "../../components/Navbar/Navbar";
import ApiService from "../../services/ApiService";
import { showNotification } from "../../services/HelperMethods";
import { UserDetailsContext } from "../../store/Store";
import "./SettingsPayment.scss";

export default function UntitledSeetings() {
  const apiService = new ApiService();

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [titles, setTitles] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(0 as any);
  const [isChecked, setIsChecked] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const [user, set] = useContext(UserDetailsContext);

  useEffect(() => {
    get();
  }, [user]);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  function get() {
    setIsLoading(true);

    apiService.getGetTicketTitle().then((result) => {
      let data = result.data;
      //console.log(data.titles[0])
      setTitles(data.titles);
    });
    setIsLoading(false);
  }
  
  function save() {
    setIsLoading(true);

    if (name == "") {
      showNotification(
        "Support Ticket Title Empty",
        "Support Ticket Title Empty",
        "error"
      );
      setIsLoading(false);
      return;
    }

    if(selectedTicket)
    {
      console.log(selectedTicket,"selectedTicket")


      let data = {
        Id : id,
        Title: name,
        Status: isChecked,
      };

      console.log(data,"update data")


      apiService
      .updateSupportTicketTitle(data)
      .then((result) => {
        let data = result.data;
        if (data.isSuccessful) {
          showNotification(
            "Support Ticket Title Updated",
            "Support Ticket Title Updated",
            "success"
          );
          get();
          setShowResults(false);
          setName("");
          setStatus(0);
        } else {
          showNotification("Unexpected Error", "Unexpected Error", "error");
        }
      })
      .catch((err) => {
        showNotification("Unexpected Error", "Unexpected Error", "error");
      });
      setIsLoading(false);
      return;
    }

    let data = {
      Title: name,
      Status: true,
    };

    apiService
      .createSupportTicketTitle(data)
      .then((result) => {
        let data = result.data;

        if (data?.isSuccess) {
          showNotification(
            "Support Ticket Title Saved",
            "Support Ticket Title Saved",
            "success"
          );
          get();
          setShowResults(false);
          setName("");
          setStatus(0);
        } else {
          showNotification("Unexpected Error", "Unexpected Error", "error");
        }
      })
      .catch((err) => {
        showNotification("Title Already Exist", "Title Already Exist", "error");
      });
    setIsLoading(false);
  }

  return (
    <div className="body settings payments">
      <h2>Settings </h2>

      <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
        <Link to="/settings/payment">Payments</Link>
        <Link to="/settings/untitled">Property</Link>
        <Link to="/settings/support" className="active">
          Support Tickets
        </Link>
      </Navbar>

      <div className="white-box mt-4">
        {isLoading ? (
          <AppLoading isLoading={isLoading} />
        ) : (
          <div className="white-box-inner">
                <div className="row align-items-center">
                  <div className="col-auto text-center">
                    <label>Support Ticket Title</label>
                  </div>
                  <div className="col">
                    <input
                      className="form-control text-center"
                      placeholder="Add Support Ticket Title"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                  {showResults &&  (
                    <div className="col-auto d-flex align-items-center">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                          handleOnChange();
                        }}
                      />
                      <label className="mb-0 ml-2" style={{ backgroundColor: "transparent" }}>
                        Active
                      </label>
                    </div>
                  )}
                  <div className="col-auto">
                        <AppButton isLoading={isLoading} onClick={() => save()}>
                          Save
                        </AppButton>
                  </div>
              </div>
          </div>
        )}
      </div>

      <div className="white-box mt-4">
        {isLoading ? (
          <AppLoading isLoading={isLoading} />
        ) : (
          <div className="white-box-inner">
            <div>
              <div>
                <Table
                  responsive="lg"
                  borderless={true}
                  id="support-tickets-table"
                >
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {titles.map((title, i) => {
                      return (
                        <tr
                          className="cursor-pointer"
                          key={i}
                          onClick={() => {
                            setName(title.title);
                            setIsChecked(title.status == true ? true : false);
                            setId(title.id);
                            setSelectedTicket(true);
                            setShowResults(true);

                          }}
                        >
                          <td>{title?.title}</td>
                          <td style={{ textAlign: "left" }}>
                            {title?.status == true ? (
                              <>
                                <Dot
                                  color={"green"}
                                  style={{
                                    marginRight: "0.5rem",
                                    color: "#E8A454",
                                  }}
                                />
                                {"Active"}
                              </>
                            ) : null}

                            {title?.status == false ? (
                              <>
                                <Dot
                                  color={"red"}
                                  style={{
                                    marginRight: "0.5rem",
                                    color: "#E8A454",
                                  }}
                                />
                                {"Inactive"}
                              </>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
