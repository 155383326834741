import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import ApiService from "../../services/ApiService";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import Navbar from "../../components/Navbar/Navbar";
import ordinalSuffixOf, {
  handleErrors,
  showNotification,
  splitWord,
} from "../../services/HelperMethods";
import Drawer from "../../components/Drawer/Drawer";
import AppButton from "../../components/AppButton/AppButton";
import SideDrawerHeader from "../../components/Drawer/DrawerHeader/SideDrawerHeader";

import "./Apartments.scss";
import Filter from "../../components/Filter/Filter";
import AppLoading from "../../components/AppLoading/AppLoading";


 function Apartments() {
  const apiService = new ApiService();

  const [apartments, setApartments] = useState([] as any[]);
  const [selectedApartment, setSelectedApartment] = useState(null as any);
  const [floor, setFloor] = useState("All");
  const [floors, setFloors] = useState(null as any);
  const [doorNumber, setDoorNumber] = useState("All");
  const [tower ,setTower] = useState("All");
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchedApartments, setSearchedApartments] = useState([] as any[]);
  const [filteredApartments, setFilteredApartments] = useState([] as any[]);
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    getApartments();
  }, []);

  async function getApartments() {
    setIsLoading(true);

    try {
      let result = (await apiService.getApartments()) as any;

      result = result.data;

      let apartments = result.apartment;

      let uniqueFloors = apartments
        .map((apartment) => apartment.floor)
        .filter((value, index, self) => self.indexOf(value) === index);

      uniqueFloors.sort(function (a, b) {
        return a - b;
      });

      setFloors(uniqueFloors);

      apartments.sort(function (a, b) {
        if (a.doorNumber < b.doorNumber) {
          return -1;
        }
        if (a.doorNumber > b.doorNumber) {
          return 1;
        }
        return 0;
      });

      apartments.sort(function (a, b) {
        return a.floor - b.floor;
      });

      setApartments(apartments);
      setSearchedApartments(apartments);
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }

  function filter() {
    setIsFiltered(true);

    setShowFilter(false);

    var filtered = [] as any;

    if (doorNumber == "All" && tower == "All" && floor == "All") {
      filtered = apartments;

      setFilteredApartments(filtered);
      setSearchedApartments(filtered);
    } else {
      if (floor != "All") {
        filtered = apartments.filter(function (apartment) {
          return apartment?.floor == floor;
        });
      }

      if (doorNumber != "All") {
        if (filtered.length > 0) {
          filtered = filtered.filter(function (apartment) {
            return apartment?.doorNumber == doorNumber;
          });
        } else {
          filtered = apartments.filter(function (apartment) {
            return apartment?.doorNumber == doorNumber;
          });
        }
      }

      if (tower != "All") {
        if (filtered.length > 0) {
          filtered = filtered.filter(function (apartment) {
            return apartment?.location == tower;
          });
        } else {
          filtered = apartments.filter(function (apartment) {
            return apartment?.location == tower;
          });
        }
      }

      setFilteredApartments(filtered);
      setSearchedApartments(filtered);
    }

    if (filtered.length == 0) {
      showNotification(
        "Filter Returned No Results",
        "Filter Returned No Results",
        "error"
      );
    }
  }

  function clear() {
    setFloor("All");
    setDoorNumber("All");
    setTower("All");
    setFilteredApartments([]);
    setIsFiltered(false);
    setShowFilter(false);
    getApartments();
  }

  function getApartmentStatus(apartment) {
    var element = <Fragment />;

    if (apartment?.owner) {
      element = <span>Sold</span>;
    }

    if (apartment?.owner == null && apartment?.tenant != null) {
      element = <span>Rented</span>;
    }

    if (
      apartment?.owner == null &&
      apartment?.tenant == null &&
      apartment?.active
    ) {
      element = <span style={{ color: "#4EBB4B" }}>Available</span>;
    }

    if (!apartment?.active) {
      element = <span style={{ color: "#f40105" }}>Inactive</span>;
    }

    return element;
  }

  function handleFilter(array: any[], searchedWord: string) {
    let searchedArray = [] as any[];
    array.forEach((apartment) => {
      if (
        apartment?.floor.toLowerCase().includes(searchedWord.toLowerCase()) ||
        apartment?.doorNumber
          .toLowerCase()
          .includes(searchedWord.toLowerCase()) ||
        apartment?.location
          ?.toLowerCase()
          .includes(searchedWord.toLowerCase()) ||
        apartment?.squareFeet
          ?.toString()
          .toLowerCase()
          .includes(searchedWord.toLowerCase()) ||
        apartment?.owner?.name
          ?.toLowerCase()
          .includes(searchedWord.toLowerCase()) ||
        apartment?.tenant?.name
          ?.toLowerCase()
          .includes(searchedWord.toLowerCase())
      ) {
        searchedArray.push(apartment);
      }
    });
    return searchedArray;
  }

  function handleSearch(searchedWord: string) {
    setIsLoading(true);
    if (searchedWord.trim() === "") {
      if (filteredApartments.length > 0 || isFiltered) {
        setSearchedApartments(filteredApartments);
      } else {
        setSearchedApartments(apartments);
      }
      setIsLoading(false);
      return;
    }

    let searchedArray = [] as any[];
    if (filteredApartments.length > 0 || isFiltered) {
      searchedArray = handleFilter(filteredApartments, searchedWord);
    } else {
      searchedArray = handleFilter(apartments, searchedWord);
    }
    setSearchedApartments(searchedArray);
    setIsLoading(false);
  }

  function handleItemClick(item: any){
    setSelectedItem(item);  
  }

  return (
    <div className="body apartments-page">
      <AppLoading isLoading={isLoading} />
      <h2>Apartments/Users</h2>

      <Navbar
        showSearch
        hideSettings={true}
        hideAdd={true}
        hideFilter={false}
        onFilterButtonClick={() => {
          setShowFilter(!showFilter);
        }}
        onSearchChange={handleSearch}
      >
        <Link className="active" to="/apartments">
          Apartments
        </Link>
        <Link to="/users">Residents</Link>
        <Link to="/staff">Staff</Link>
      </Navbar>

      <Table responsive="lg" borderless={true}>
        <thead>
          <tr>
            <th>Floor</th>
            <th>Door Number</th>
            <th>Tower</th>
            <th>Sq. Feet</th>
            <th>Owner</th>
            <th>Tenant</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {searchedApartments.map((apartment, i) => {
            return (
              <tr
                className="cursor-pointer"
                onClick={() => {
                  setSelectedApartment(apartment);
                  handleItemClick(apartment);
                }}
                key={i}
                style={{
                  backgroundColor: selectedItem === apartment ? '#e1fce1' : 'transparent',
                }}
              >
                <td>{apartment.floor}</td>
                <td>{apartment.doorNumber}</td>
                <td>{apartment?.location}</td>
                <td>{apartment.squareFeet}</td>
                <td>{apartment.owner?.name}</td>
                <td>{apartment.tenant?.name}</td>
                <td>{getApartmentStatus(apartment)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Drawer isOpen={selectedApartment != null}>
        <SidebarUI />
      </Drawer>

      <Filter
        children={[
          {
            label: "Floor",
            type: (
              <select
                style={{ flex: "2", margin: "0rem 0.8rem" }}
                className="form-control"
                onChange={(e) => {
                  setFloor(e.target.value);
                }}
                value={floor}
              >
                <option value={"All"}>All</option>
                {floors?.map((floor: any, index) => {
                  return (
                    <option value={floor} key={index}>
                      {floor}
                    </option>
                  );
                })}
              </select>
            ),
          },
          {
            label: "Door Number",
            type: (
              <select
                style={{ flex: "2", margin: "0rem 0.8rem" }}
                className="form-control"
                onChange={(e) => {
                  setDoorNumber(e.target.value);
                }}
                value={doorNumber}
              >
                <option value={"All"}>All</option>
                {apartments?.map((apartment: any, index) => {
                  return (
                    <option value={apartment?.doorNumber} key={index}>
                      {apartment?.doorNumber}
                    </option>
                  );
                })}
              </select>
            ),
          },
          {
            label: "Tower",
            type: (
              <select
                style={{ flex: "2", margin: "0rem 0.8rem" }}
                className="form-control"
                onChange={(e) => {
                  setTower(e.target.value);
                }}
                value={tower}
              >
                <option value={"All"}>All</option>
                {apartments?.map((apartment: any, index) => {                  
                  if (apartment?.location != null) {
                    return (
                      <option value={apartment?.location} key={index}>
                        {apartment?.location}
                      </option>
                    );
                  }

                  return <Fragment key={index} />;
                })}
              </select>
            ),
          },
        ]}
        isOpen={showFilter}
        wrapperClass={"filter-area-apartment"}
        showClear={true}
        onFilter={() => {
          filter();
        }}
        onClear={() => {
          clear();
        }}
      />
    </div>
  );

  function SidebarUI() {
    const [working, setWorking] = useState(false);
    return (
      <div>
        <SideDrawerHeader
          onClose={() => {
            setSelectedApartment(null);
            setSelectedItem(null);
          }}
        >
          <div>
            <h4 className="apartment-name">
              {ordinalSuffixOf(selectedApartment?.floor)}
              {" Floor, "}
              {selectedApartment?.doorNumber}
            </h4>
          </div>
        </SideDrawerHeader>
        <div className="container apartments-drawer">
          <div className="wrapper-apartments mt-4 mb-4">
            <div className="wrapper-apartment">
              {selectedApartment?.owner != null ? (
                <>
                  <div className="wrapper-title ">
                    <p className="title">Apartment Owner &amp; Dependants</p>
                  </div>
                  <div className="user-list-item">
                    <div className="profile-wrap">
                      <ProfileImage
                        className="small"
                        src={selectedApartment?.owner?.profilePicturePath}
                      />
                      <div className="wrapper-name">
                        <p className="title">{selectedApartment?.owner.name}</p>
                        <div className="sub-title">
                          {splitWord(selectedApartment?.owner?.userType)}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Fragment />
              )}
              {selectedApartment?.ownerDependents?.map((user, index) => {
                return (
                  <div key={index} className="user-list-item">
                    <div className="profile-wrap">
                      <ProfileImage
                        className="small"
                        src={user?.profilePicturePath}
                      />
                      <div className="wrapper-name">
                        <p className="title">{user?.name}</p>
                        <div className="sub-title">
                          {splitWord(user?.userType)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="wrapper-apartments mt-4 mb-4">
            <div className="wrapper-apartment">
              {selectedApartment?.tenant != null ? (
                <>
                  <div className="wrapper-title ">
                    <p className="title">Tenant &amp; Dependants</p>
                  </div>
                  <div className="user-list-item">
                    <div className="profile-wrap">
                      <ProfileImage
                        className="small"
                        src={selectedApartment?.tenant?.profilePicturePath}
                      />
                      <div className="wrapper-name">
                        <p className="title">
                          {selectedApartment?.tenant?.name}
                        </p>
                        <div className="sub-title">
                          {splitWord(selectedApartment?.tenant?.userType)}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Fragment />
              )}
              {selectedApartment?.tenantDependents?.map((user, index) => {
                return (
                  <div key={index} className="user-list-item">
                    <div className="profile-wrap">
                      <ProfileImage
                        className="small"
                        src={user?.profilePicturePath}
                      />
                      <div className="wrapper-name">
                        <p className="title">{user?.name}</p>
                        <div className="sub-title">
                          {splitWord(user?.userType)}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {selectedApartment?.active ? (
            <AppButton
              onClick={() => {
                handleApartmentStatus(false);
              }}
              className="link-disable-user"
              isLoading={working}
              loadingColor={"#f40105"}
            >
              Disable Apartment
            </AppButton>
          ) : (
            <AppButton
              onClick={() => {
                handleApartmentStatus(true);
              }}
              isLoading={working}
            >
              Enable Apartment
            </AppButton>
          )}
        </div>
      </div>
    );

    async function handleApartmentStatus(isActive) {
      setWorking(true);

      try {
        let data = {
          apartmentId: selectedApartment.id,
          isActive: isActive,
        };

        await apiService.updateApartment(data);

        await getApartments();

        setSelectedApartment(null);

        showNotification("", "Updated", "success");
      } catch (error) {
        handleErrors(error);
      }

      setWorking(false);
    }
  }
}
export default Apartments;