import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";

import "./Payment.scss";
import "./Payroll.scss";

export default function () {
  return (
    <div className="body">
      <h2>Accounting</h2>

      <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
        <Link to="/payment">Invoices</Link>
        <Link to="/payment/settlements">Settlements</Link>
        <Link className="active" to="/payment/payroll">Payroll</Link>
        <Link to="/payment/bills">Expenses</Link>
      </Navbar>

      <div style={{ fontSize: "2rem", textAlign: "center", color: "#8acb87" }}>Coming Soon</div>
    </div>
  );
}
