import "./Style.scss";
import LiweIcon from "../../assets/images/liwe.png";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="icon">
        <img className="company-icon" src={LiweIcon} />
      </div>
      <div className="heading">Privacy Policy</div>

      <div className="section">
        {/* section-1 */}
        <div className="section-1">
          <div className="section-heading">Introduction</div>
          <div>
            <p>
              We created LIWE to enable seamless communication between
              management and residents, empower condominium managers with
              advanced and easy to use condominium management tools and to
              encourage residents to connect with each other in a way never
              before possible, in order to create vibrant and connected
              condominium communities.{" "}
            </p>
            <p>
              We love our condominium communities at LIWE. It is extremely
              important to us that you not only have a pleasant experience
              using, communicating and interacting using the LIWE platform, but
              also that you feel comfortable an safe while doing so.{" "}
            </p>
            <p>
              The LIWE website{" "}
              <a href="https://liwecommunities.com/">www.liwecommunities.com</a>{" "}
              (“Website”) and LIWE mobile application (“App”) are owned and
              operated by We Live Communities Pvt. Ltd. (collectively “LIWE”,
              “We Live Communities”, “we”, “us”, “our”). This Policy applies to
              you if you browse, access or use this Website, or download,
              install or using the LIWE App.{" "}
            </p>
            <p>
              This Privacy Policy supplements our Terms of Service and explains
              how we handle information collected and received in the course of
              your use of our websites, mobile application and services
              (collectively, the “Services”). It also governs the collection,
              use and disclosure of your information.{" "}
            </p>
          </div>
        </div>

        {/* section 2 */}
        <div className="section-2">
          <div className="section-heading">
            Information and Personal Data We Collect and Receive
          </div>
          <div>
            <p>
              We undertake to comply, at all times, with the provisions of the
              Personal Data Protection Act No.9 of 2022 in the provision of
              services. In the event of any inconsistency between the provisions
              of the said Act and this Policy, the provisions of the said Act
              shall prevail.{" "}
            </p>
            <p>
              Personal Data means any data about an individual who can be
              identified from that data. It includes without limitation and
              individual’s name, identity card, passport or other identification
              number, telephone numbers, address, email address and images
              uploaded by the user.{" "}
            </p>
            <p>
              We may collect and receive information from you in the following
              situations:
              <ul>
                <li>When you register for an account with us (“Account”). </li>
                <li>
                  When you use the features and functions available on our
                  Services, for example, by creating listings, posting
                  discussion topics and comments, interacting with the other
                  users through our chat feature, communicating with condominium
                  management, make facilities bookings, support tickets and
                  payments or reaching out to our useful contact partners.{" "}
                </li>
                <li>
                  When you visit our website or use the app, LIWE automatically
                  receives and records some information from your browser or
                  mobile phone such as IP (Internet Protocol) address, cookies
                  and data.{" "}
                </li>
                <li>
                  If you contact us, example, with a query or to report a
                  problem.
                </li>
              </ul>
            </p>
          </div>
        </div>

        {/* section 3 */}
        <div className="section-3">
          <div className="section-heading">Your Interactions With Others</div>
          <div>
            <p>
              Our Services offer features which allow users to connect and
              communicate with each other. Please use common sense and exercise
              good judgment when sharing information in these groups or with
              others. We generally do not monitor or remove the information
              which you share and such information may remain available on our
              services after your Account has been deactivated, terminated or is
              otherwise cloud. You are responsible for the information you
              choose to share with other users.{" "}
            </p>
          </div>
        </div>

        {/* section 4 */}
        <div className="section-4">
          <div className="section-heading">
            How We Use The Information We Collect
          </div>
          <div>
            <p>
              We use the information and personal data collected and received
              from you for the following purposes;
              <ul>
                <li>
                  To verify and authenticate your identity, update our records
                  and generally maintain your Account with us.{" "}
                </li>
                <li>
                  Provide our Services and customize the content shown to you.{" "}
                </li>
                <li>
                  Develop, operate, improve, deliver and maintain our Services.{" "}
                </li>
                <li>Responding to your queries and resolving complaints. </li>
                <li>
                  Carry out polls, surveys, analysis and research on how our
                  Services are being used and how we can improve them.{" "}
                </li>
                <li>
                  To update you marketing and/or promotional materials on the
                  latest products and services of LIWE or carefully selected
                  third parties. These updates will be sent by email, sms and/or
                  through push notifications and you can choose to unsubscribe
                  from these updates.{" "}
                </li>
                <li>
                  To send you service-related messages including, without
                  limitation, a welcome/confirmation notification when you
                  register your Account, facilities booking related
                  notification, social feed updates, chat notifications,
                  notifications with the condominium management and to enforce
                  our Terms of Service and other policies. These messages are
                  not promotional in nature. These messages will be sent by
                  email, sms and/or through push notifications and you may not
                  opt-out of receiving service-related messages from LIWE,
                  unless you deactivate your account.{" "}
                </li>
                <li>
                  LIWE may also collect, use and disclose your personal data for
                  any other purpose which is permitted or required under the
                  Applicable Law.{" "}
                </li>
              </ul>
            </p>
          </div>
        </div>

        {/* section 5 */}
        <div className="section-5">
          <div className="section-heading">
            Who We Share Your Information With
          </div>
          <div>
            <p>
              We may share information about you in the following ways:
              <ul>
                <li>
                  When you register for an Account, the information which you
                  make available on your profile may be viewed by other users of
                  our Services. Other users of our Services would also be able
                  to view the content which you upload and share on our
                  Services.{" "}
                </li>
                <li>
                  We may share the information with our affiliates and with
                  third party service providers who assist us in performing
                  certain aspects of our Services on our behalf, such as
                  processing transactions, fulfilling requests for information,
                  receiving and sending communications, updating marketing
                  lists, analyzing data, providing support services or in other
                  tasks. Such Service Providers will only have access to your
                  personal information to the extent necessary to perform their
                  functions.
                </li>
                <li>
                  We may share the information with our potential partners,
                  investors and other parties with a view to a potential
                  business partnership, collaboration, joint venture or
                  otherwise in furtherance of our business.{" "}
                </li>
                <li>
                  We may disclose your personal information if required to do so
                  by law or if we believe that such action is necessary to
                  prevent fraud or crime or to protect our Services or the
                  rights, property or personal safety of any person.{" "}
                </li>
              </ul>
            </p>
            <p>
              We respect your privacy and we will not sell your personal
              information to any third party. We may disclose data and aggregate
              statistics about users of our Services and sales to prospective
              partners, advertisers, sponsors and other reputable third parties
              in order to describe our Services, deliver targeted advertisements
              or for other lawful purposes, but these data and statistics will
              not include information which can be used to identify you.{" "}
            </p>
          </div>
        </div>

        {/* section 6 */}
        <div className="section-6">
          <div className="section-heading">Transfer of Information</div>
          <div>
            <p>
              By using our Services, you authorize us to use your information in
              Sri Lanka and other countries where LIWE operates for the purposes
              mentioned above. We will ensure that your information is
              transferred in accordance with this Privacy Policy and protected
              in accordance with applicable laws on personal data protection in
              Sri Lanka or any other country where LIWE operate in.{" "}
            </p>
          </div>
        </div>

        {/* section 7 */}
        <div className="section-7">
          <div className="section-heading">Protecting Your Information</div>
          <div>
            <p>
              The security of your information is important to us. We have
              security measures in place to protect against the loss, misuse and
              alteration of information under our control. We also follow
              generally accepted industry standards to protect the information
              transmitted to us over the Internet, both during transmission and
              once we receive it. Sensitive information (such as credit card
              numbers) are encrypted using most modern technology available.{" "}
            </p>
            <p>
              However, no method of transmission over the Internet, or method of
              electronic storage, is 100% secure. Therefore, while we strive to
              use commercially acceptable means to protect your information, we
              cannot guarantee its absolutely security.{" "}
            </p>
            <p>
              You undertake to treat your username, password and order details
              confidentially and not make it available to unauthorized third
              parties.{" "}
            </p>
            <p>
              We cannot assume any liability of misuse of your LIWE username,
              password or order details, except as stated in the Terms of Use.{" "}
            </p>
            <p>
              LIWE will retain your information for as long as your account is
              active or as needed to provide you services. If you no longer want
              LIWE to use your information to provide you services, you may
              close your account.{" "}
            </p>
            <p>
              LIWE will retain and use your information as necessary to comply
              with our legal obligations, resolve disputes, and enforce our
              agreements.{" "}
            </p>
            <p>
              It is important that you protect against unauthorized access of
              your Account and information by choosing your password carefully,
              and keeping your password and Account secure (e.g. by signing out
              after using our Services).{" "}
            </p>
          </div>
        </div>

        {/* section 8 */}
        <div className="section-8">
          <div className="section-heading">
            Accessing And Updating Your Information{" "}
          </div>
          <div>
            <p>
              You may access and update your information within our Services
              through the “Settings” page of your Account and/or with the
              assistance of the condominium management.{" "}
            </p>
            <p>
              Where you provide personal information about yourself to us, you
              are responsible for providing accurate, not misleading, complete
              and up-to-date information about yourself and any other person
              whose personal information you provide us, and to update this
              personal information as and when it becomes inaccurate,
              misleading, incomplete or out-of-date.{" "}
            </p>
            <p>
              In certain circumstances, you may need to provide to us personal
              information about someone other than yourself. If so, we rely on
              you to inform these individuals that you are providing their
              personal information to LIWE, to make sure they consent to you
              giving us their information and to advise them about where they
              can find a copy of this Privacy Policy.{" "}
            </p>
            <p>
              If you wish to:
              <ul>
                <li>
                  (a)Apply for a copy of the information we hold about you; or{" "}
                </li>
                <li>
                  (b)Withdraw consent you previously provided to us to use,
                  collect or disclose the information we hold about you, please
                  contact or office at the contact details set out in Section 12
                  below.{" "}
                </li>
              </ul>
            </p>
            <p>
              Please allow us a reasonable period of time to respond to any
              request and effect any change. We may ask to verify your identity
              and for more information about your request. Where we are legally
              permitted to do so, we may refuse your request and may give you
              reasons for doing so.{" "}
            </p>
            <p>
              Where you have requested for a copy of the information we hold
              about you, we may charge a reasonable administrative fee to cover
              the costs of responding to your request. If we decide to do so, we
              will provide you with a written estimate of such fee beforehand.{" "}
            </p>
          </div>
        </div>

        {/* section 9 */}
        <div className="section-9">
          <div className="section-heading">Tracking Technologies</div>
          <div>
            <p>
              We use tracking technologies on our Services. These enable us to
              understand how you use our Services which, in turn, helps us to
              provide and improve our Services. However, the tracking
              technologies do not access, receive or collect information which
              can be used to identify you.{" "}
            </p>
            <p>
              We have listed below some examples of the tracking technologies
              used on our Services:
              <ul>
                <li>
                  Cookies. A cookie is a small data file sent from a website to
                  your browser that is stored on your device. Cookies are used
                  for a number of purposes, including to display the most
                  appropriate content based on your interests and activity on
                  our Services, estimate and report our Service’s total audience
                  size and traffic, and conduct research to improve our
                  Services. You can configure cookies, you will not be able to
                  take full advantage of our Services.{" "}
                </li>
                <li>
                  Clear gifs. We employ a software technology called clear gifs
                  (a.k.a. web beacons or web bugs) to help us better manage
                  content on our Services by informing us what content is
                  effective. Clear gifs are tiny graphics with unique identifier
                  similar in function to cookies, and are used to track te
                  online movements of web users. In contrast to cookies, which
                  are stored on a user’s computer hard drive, clear gifs are
                  embedded invisibly on web pages and are about the size of the
                  period at the end of this sentence.{" "}
                </li>
                <li>
                  Flash cookies. We use flash cookies (a.k.a. local shared
                  objects or LSOs) to store some of your viewing preferences on
                  our Services. These are used to collect and store information,
                  but differ from browser cookies in the amount, type and manner
                  in which data is stored.{" "}
                </li>
              </ul>
            </p>
          </div>
        </div>

        {/* section 10 */}
        <div className="section-10">
          <div className="section-heading">Changes To This Privacy Policy</div>
          <div>
            <p>
              We reserve the right to make changes to this Privacy Policy at any
              time and all changes will be posted here. If we believe that the
              changes are material, we will notify you of the changes by posting
              a notice on our Services or by email. You are responsible for
              reviewing the changes which we make this Privacy Policy. You
              continued use of our Services constitutes your acceptance of the
              updated Privacy Policy.{" "}
            </p>
          </div>
        </div>

        {/* section 11 */}
        <div className="section-11">
          <div className="section-heading">
            Third Party Sites And Resources{" "}
          </div>
          <div>
            <p>
              Our Services may, from time to time, contain links to external
              sites or resources which are operated by third parties. We have no
              control over the content and privacy practices of such sites and
              resources. You are advised to review the privacy policies of these
              sites and resources operated by third parties and understand how
              your information may be used by those third parties.{" "}
            </p>
          </div>
        </div>

        {/* section 12 */}
        <div className="section-12">
          <div className="section-heading">Disclaimer</div>
          <div>
            <p>No reliance on information. </p>
            <p>
              LIWE makes no representations or warranties in relation to the
              Confidential Information, including the completeness of any
              information provided.{" "}
            </p>
            <p>
              The User may rely solely upon its own analysis and assessment of
              the Confidential Information and must satisfy itself as to the
              accuracy and completeness of the Confidential Information.{" "}
            </p>
            <p>Disclaimer of liability. </p>
            <p>
              To the maximum extent permitted by law, the LIWE disclaims all
              liability for any loss or damage suffered by any person using,
              disclosing, relying or acting on any information disclosed by the
              Provider or any of its Representatives.{" "}
            </p>
          </div>
        </div>

        {/* section 13 */}
        <div className="section-13">
          <div className="section-heading">Contact Us</div>
          <div>
            <p>
              If you have any questions, complaints, concerns or comments on our
              Privacy Policy, we welcome you to contact us by sending an email
              to <b>info@liwecommunities.com</b>
            </p>
            <p>
              Your indication at the subject header would assist us in attending
              to your email speedily by passing it on to the relevant staff in
              our organization.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
