import React, { useContext, useEffect, useState } from "react";
import { UserDetailsContext } from "./store/Store";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, HashRouter, Redirect, Route, Switch, useHistory, } from "react-router-dom";

import Dashboard from "./pages/Dashboard/Dashboard";
import Apartments from "./pages/ApartmentsUsers/Apartments";
import Sidebar from "./components/Sidebar/Sidebar";
import Users from "./pages/ApartmentsUsers/Users";
import SupportTickets from "./pages/Feedback/SupportTickets";
import CompletedSupportTickets from "./pages/Feedback/CompletedSupportTickets"
import CanceledSupportTickets from "./pages/Feedback/CanceledSupportTickets";
import Feedback from "./pages/Feedback/Feedback";
import ManageFacilities from "./pages/Facilities/ManageFacilities";
import login from "./pages/Login/Login";
import VerifyAccount from "./pages/VerifyAccount/VerifyAccount";
import VerifyPhone from "./pages/VerifyPhone/VerifyPhone";
import VerificationResult from "./pages/VerificationResult/VerificationResult";
import Header from "./components/Header/Header";
import SelectCondominium from "./pages/SelectCondominium/SelectCondominium";
import FacilityBookings from "./pages/Facilities/FacilityBookings";
import AddFacility from "./pages/Facilities/AddFacility";
import CompoundSecurity from "./pages/CompoundSecurity/CompoundSecurity";
import SecurityRoster from "./pages/CompoundSecurity/SecurityRoster";
import Payment from "./pages/Payments/Payment";
import Messages from "./pages/Messaging/Messages";
import FirebaseServices from "./services/FirebaseServices";
import Settlements from "./pages/Payments/Settlements";
import Payroll from "./pages/Payments/Payroll";
import Bills from "./pages/Payments/Bills";
import QrGenerator from "./pages/QrGenerator/QrGenerator";
import VisitorForm from "./pages/QrGenerator/VisitorForm";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import PublicNotices from "./pages/PublicNotices/PublicNotices";
import PublicNoticesArchive from "./pages/PublicNoticesArchive/PublicNoticesArchive";
import SettingsPayment from "./pages/Settings/SettingsPayment";
import UntitledSettings from "./pages/Settings/UntitledSettings";
import SettingsVendorManagement from "./pages/Settings/SettingsVendorManagement";
import SettingsUserManagement from "./pages/Settings/SettingsUserManagement";
import InvoiceAgingReport from "./pages/Reports/InvoiceAgingReport";
import FacilityUsageReport from "./pages/Reports/FacilityUsageReport";
import VisitorReports from "./pages/Reports/VisitorReports";
import SecurityRosterReports from "./pages/Reports/SecurityRosterReports";
import AppUsageReport from "./pages/Reports/AppUsageReport";
import TransactionReport from "./pages/Reports/TransactionReport";
import SupportTicketReport from "./pages/Reports/SupportTicketReport";
import ApiService from "./services/ApiService";
import AppLoading from "./components/AppLoading/AppLoading";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ContactUs from "./pages/ContactUs/ContactUs";
import FAQ from "./pages/FAQ/FAQ";

import "react-toastify/dist/ReactToastify.css";
import "./Style.scss";
import "./AdminStyles.scss";
import UntitledSeetings from "./pages/Settings/UntitledSettings";
import SupportSetting from "./pages/Settings/SupportTicket";
import PrintInvoice from "./pages/Payments/PrintInvoice";
import Staff from "./pages/ApartmentsUsers/Staff";
import AdminDashboard from "./pages/Admin/pages/AdminDashboard";
import Broadcast from "./pages/Messaging/Broadcast";
import MarketplaceAdmin from "./pages/Marketplace/Admin";
import MarketplaceAdminView from "./pages/Marketplace/AdminView";
import Bulk from "./pages/Admin/pages/AdminBulkUpload";
import AdminCondominiums from "./pages/Admin/pages/AdminCondominiums";
import AdminUsers from "./pages/Admin/pages/AdminUsers";
import AdminApartments from "./pages/Admin/pages/AdminApartments";
import AdminLogin from "./pages/Admin/pages/AdminLogin";
import AdminCondoSelectTop from "./pages/Admin/AdminComponents/AdminCondoSelectTop";
import AdminSidebar from "./pages/Admin/AdminComponents/AdminSidebar";
import { Notifications } from 'react-push-notification';
import { pink } from "@material-ui/core/colors";
import AdminRefundTickets from "./pages/Admin/pages/AdminRefundTickets";
import SelectApartmentScreen from "./components/AddUser/SelectApartmentScreen";
import ManagementSelfReg from "./pages/ManagementSelfReg/ManagementSelfReg";
import RequestBuilding from "./pages/ManagementSelfReg/RequestBuilding";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";


function App() {

    const apiService = new ApiService();

    const firebaseServices = new FirebaseServices();

    const [user, setUser] = useContext(UserDetailsContext);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        firebaseServices.initFirebase();
        getUserDetails();
    }, []);

    async function getUserDetails() {
        try {
            let user = await apiService.getUserDetails();

            user = user.data;

            setUser(user);
        } catch (error) { }
        setIsLoading(false);
    }

    return (
        <div className="App">
            <Notifications />
            <ToastContainer hideProgressBar={true}/>
            <AppLoading color="#8ACB87" style={{ left: 0, backgroundColor: "white", zIndex: 15 }} isLoading={isLoading} />
            <HashRouter>
                <Switch>
                    <Route path="/self-registration" exact component={ManagementSelfReg}></Route>
                    <Route path="/privacy-policy" exact component={PrivacyPolicy}></Route>
                    <Route path="/terms-and-conditions" exact component={TermsAndConditions}></Route>
                    <Route path="/admin">
                        <div className="dashboard-admin-layout admin-wrap">
                            <AdminSidebar />
                            <div>
                                <AdminCondoSelectTop />
                                <div className="p-4">
                                    <Switch>
                                        <Route path="/admin/dashboard" exact component={AdminDashboard} />
                                        <Route path="/admin/condominium" exact component={AdminCondominiums} />
                                        <Route path="/admin/apartments" exact component={AdminApartments} />
                                        <Route path="/admin/users" exact component={AdminUsers} />
                                        <Route path="/admin/bulk" exact component={Bulk} />
                                        <Route path="/admin/RefundTickets" exact component={AdminRefundTickets} />
                                        <Route path="/admin/RequestBuilding" exact component={RequestBuilding} />
                                        <Route path="/admin/:clearData?" exact component={AdminLogin} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </Route>
                    <Route path="/mtpadmin">
                        <Switch>
                            <Route path="/mtpadmin" exact component={MarketplaceAdmin} />
                            <Route path="/mtpadmin/view" exact component={MarketplaceAdminView} />
                        </Switch>
                    </Route>
                    <Route path="/start">
                        <Switch>
                            <Route path={"/start/qr/:inviteCode"} exact component={QrGenerator} />
                            <Route path={"/start/visitor-form/:id"} exact component={VisitorForm} />
                            <Route path="/start/login/:clearData?" exact component={login} />
                            <Route path="/start/verify-account" exact component={VerifyAccount} />
                            <Route path="/start/verify-phone" exact component={VerifyPhone} />
                            <Route path="/start/verification-result" exactcomponent={VerificationResult} />
                            <Route path="/start/reset-password/:token" exact component={ResetPassword} />
                            <Route path="/start/feedback/:type" exact component={ContactUs} />
                            <Route path="/start/faq" exact component={FAQ} />
                            <Route path="/start/select-condominium" exact component={SelectCondominium} />
                            <Route path="/start/forgot-password" exact component={ForgotPassword} />
                            <Route path="/start/print/:invoiceId" exact component={PrintInvoice} />
                        </Switch>
                    </Route>

                    <Route path="/">
                        <div className="dashboard-layout">
                            <Sidebar />
                            <div>
                                <Header />
                                <Switch>
                                    <Route path="/" exact>
                                        <Redirect to={"/start/login"} />
                                    </Route>
                                    <Route path="/dashboard" exact component={Dashboard} />
                                    <Route path="/apartments" exact component={Apartments} />
                                    <Route path="/users/:id?" exact component={Users} />
                                    <Route path="/select-apartment/:id?" exact component={SelectApartmentScreen} />
                                    <Route path="/staff/:id?" exact component={Staff} />
                                    <Route path="/support-tickets" exact component={SupportTickets} />
                                    <Route path="/completed-support-tickets" exact component={CompletedSupportTickets} />
                                    <Route path="/cancelled-support-tickets" exact component={CanceledSupportTickets} />
                                    <Route path="/feedback" exact component={Feedback} />
                                    <Route path="/compound-security" exact component={CompoundSecurity} />
                                    <Route path="/security-roster" exact component={SecurityRoster} />
                                    <Route path="/facility/manage" exact component={ManageFacilities} />
                                    <Route path="/facility/bookings" exact component={FacilityBookings} />
                                    <Route path="/facility/add/:id?" component={AddFacility} />
                                    <Route path="/payment/settlements" exact component={Settlements} />
                                    <Route path="/payment/payroll" exact component={Payroll} />
                                    <Route path="/payment/bills" exact component={Bills} />
                                    <Route path="/payment/:id?" exact component={Payment} />
                                    <Route path="/notices/public" exact component={PublicNotices} />
                                    <Route path="/notices/archived" exact component={PublicNoticesArchive} />
                                    <Route path="/messages" exact component={Messages} />
                                    <Route path="/broadcast" exact component={Broadcast} />
                                    {/* <Route path="/staff" exact component={Staff} /> */}
                                    <Route path="/settings/payment" exact component={SettingsPayment} />
                                    <Route path="/settings/untitled" exact component={UntitledSeetings} />
                                    <Route path="/settings/support" exact component={SupportSetting} />
                                    <Route path="/settings/vendorManagement" exact component={SettingsVendorManagement} />
                                    <Route path="/settings/userManagement" exact component={SettingsUserManagement} />
                                    <Route path="/reports/invoiceAgingReport" exact component={InvoiceAgingReport} />
                                    <Route path="/reports/facilityUsageReport" exact component={FacilityUsageReport} />
                                    <Route path="/reports/visitorReports" exact component={VisitorReports} />
                                    <Route path="/reports/securityRosterReports" exact component={SecurityRosterReports} />
                                    <Route path="/reports/appUsageReport" exact component={AppUsageReport} />
                                    <Route path="/reports/transactionReport" exact component={TransactionReport} />
                                    <Route path="/reports/supportTicketReport" exact component={SupportTicketReport} />
                                </Switch>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </HashRouter>
        </div>
    );
}

export default App;
