import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Table } from "react-bootstrap";
import ReactToExcel from "react-html-table-to-excel";
import moment from "moment";
import { DatePicker } from "antd";
import AppButton from "../../components/AppButton/AppButton";
import ApiService from "../../services/ApiService";
import { formatMoney, showNotification } from "../../services/HelperMethods";
import AppLoading from "../../components/AppLoading/AppLoading";

import "./Reporting.scss";

export default function InvoiceAgingReport() {

  let apiService = new ApiService();

  const [fromDate, setFromDate] = useState(moment().add("-1", "days"));
  const [toDate, setToDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceAgingData, setInvoiceAgingData] = useState([] as any[])

  useEffect(() => {
    getInvoiceAgingData()
  }, [])

  async function getInvoiceAgingData() {

    setIsLoading(true)

    let data = {
      fromDate: fromDate,
      toDate: toDate
    }

    await apiService.getInvoiceAgingReports(data).then((result) => {

      let data = result.data;

      setInvoiceAgingData(data?.agingInvoices)
    }).catch((err) => {
      showNotification("Unexpected Error", "Unexpected Error", "error")
    })

    setIsLoading(false)
  }

  return (
    <div className="body ">
      <h2>Reports</h2>

      <div>
        <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
          <Link to="#" className="active">Invoice Aging Report</Link>
          <Link to="/reports/facilityUsageReport">Facility Usage Report</Link>
          <Link to="/reports/visitorReports">Visitor Reports</Link>
          {/* <Link to="/reports/securityRosterReports">Security Roster Reports</Link> */}
          {/* <Link to="/reports/appUsageReport">App Usage Report</Link> */}
          {/* <Link to="/reports/transactionReport">Transaction Report</Link> */}
          <Link to="/reports/supportTicketReport">Support Tickets Report</Link>
        </Navbar>
      </div>

      <div className="filter-area">
        <AppLoading isLoading={isLoading} />
        <div className="filter-area-item">
          <span>From : </span>
          <DatePicker defaultValue={moment().add("-1", "days")} onChange={(date) => { if (date) { setFromDate(date); } }}
            format={"DD/MM/YYYY"} allowClear={false} />
        </div>

        <div className="filter-area-item">
          <span>To : </span>
          <DatePicker defaultValue={moment()} onChange={(date) => { if (date) { setToDate(date); } }} format={"DD/MM/YYYY"}
            disabledDate={(date) => { let isd = moment(fromDate); return !date || date.isBefore(isd); }} allowClear={false} />
        </div>

        <div className="filter-area-item">
          <AppButton onClick={() => { getInvoiceAgingData() }}>Filter</AppButton>
        </div>

        <div className="filter-area-item">
          <ReactToExcel className="download-table-xls-button filter-button" table="table-to-xlx" filename="InvoiceAgingReport"
            sheet="sheet1" buttonText="Download Report" />
        </div>
      </div>

      <div style={{ maxWidth: "76rem", overflow: "auto" }}>
        <Table responsive="lg" borderless={true} id="table-to-xlx">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Apt. Number</th>
              <th>Total Arrears</th>
              <th>0-30 Days</th>
              <th>31-60 Days</th>
              <th>61-90 Days</th>
              <th>90+ Days</th>
            </tr>
          </thead>

          <tbody>
            {invoiceAgingData?.length > 0
              ? (invoiceAgingData?.map((invoice, index) => {
                return (
                  <tr key={index}>
                    <td>{invoice?.name}</td>
                    <td>{invoice?.apartment}</td>
                    <td>{"LKR" + formatMoney(invoice?.totalArrears)}</td>
                    <td>{"LKR" + formatMoney(invoice?.lessThan31)}</td>
                    <td>{"LKR" + formatMoney(invoice?.lessThan61)}</td>
                    <td>{"LKR" + formatMoney(invoice?.lessThan90)}</td>
                    <td>{"LKR" + formatMoney(invoice?.moreThan90)}</td>
                  </tr>
                )
              }))
              : (<Fragment />)}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
