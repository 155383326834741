import { Link } from "react-router-dom";
import React, { Fragment, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import DashboardIcon from "../../assets/images/icons/dashboard.svg";
import CompoundSecurityIcon from "../../assets/images/icons/shield.svg";
import ApartmentsIcon from "../../assets/images/icons/apartment.svg";
import FacilitiesIcon from "../../assets/images/icons/gym.svg";
import PaymentsIcon from "../../assets/images/icons/credit-card.svg";
import FeedbackIcon from "../../assets/images/icons/feedback.svg";
import SettingsIcon from "../../assets/images/icons/settings.svg";
import { useLocation } from "react-router-dom";
import { UserDetailsContext } from "../../store/Store";
import packageJson from '../../../package.json';
import moment from "moment";

import "./Sidebar.scss";

const appVersion = packageJson.version;

export default function Sidebar() {

  const [user] = useContext(UserDetailsContext);
  return (
    
    <div className="sidebar-wrapper">
      <div className="sidenav">
        <div className="image-wrapper">
          <img className="logo-img" src="/images/logo.png" />
          </div>
          <div className="condo-name-wrapper">
            <p className="condo-name">{user?.buildingName}</p>
          </div>

        <div className="side-menu">
          <SidebarLink to={"/dashboard"}>
            <div className="nav-item">
              <img src={DashboardIcon} />
              <div className="text">Dashboard</div>
            </div>
          </SidebarLink>

          <SidebarLink to={"/compound-security"}>
            <div className="nav-item">
              <img src={CompoundSecurityIcon} />
              <div className="text">Compound</div>
            </div>
          </SidebarLink>

          {user?.userType == "Accountant" || user?.userType == "Engineer" || user?.userType == "HelpDesk"
            || user?.userType == "Administration"
            ? (<Fragment />)
            : (<SidebarLink to={"/apartments"}>
              <div className="nav-item">
                <img src={ApartmentsIcon} />
                <div className="text">Apartments/Users</div>
              </div>
            </SidebarLink>)
          }

          {user?.userType == "Accountant" || user?.userType == "Engineer"
            ? (<Fragment />)
            : (<SidebarLink to={"/facility/manage"}>
              <div className="nav-item">
                <img src={FacilitiesIcon} />
                <div className="text">Facilities</div>
              </div>
            </SidebarLink>)
          }

          {user?.userType == "Engineer" || user?.userType == "HelpDesk"
            ? (<Fragment />)
            : (<SidebarLink to={"/payment"}>
              <div className="nav-item">
                <img src={PaymentsIcon} />
                <div className="text">Accounting</div>
              </div>
            </SidebarLink>)
          }

          {user?.userType == "Accountant"
            ? (<Fragment />)
            : (<SidebarLink to={"/notices/public"}>
              <div className="nav-item">
                <img src={FeedbackIcon} />
                <div className="text">Notices</div>
              </div>
            </SidebarLink>)
          }

          {user?.userType == "Engineer" || user?.userType == "HelpDesk" || user?.userType == "Administration"
            ? (<Fragment />)
            : (<SidebarLink to={"/settings/payment"}>
              <div className="nav-item">
                <img src={SettingsIcon} />
                <div className="text">Settings</div>
              </div>
            </SidebarLink>)
          }

          {user?.userType == "HelpDesk"
            ? (<Fragment />)
            : (<SidebarLink to={"/reports/invoiceAgingReport"}>
              <div className="nav-item">
                <i style={{ color: "white", fontSize: 20 }} className="far fa-file-alt" />
                <div className="text">Reports</div>
              </div>
            </SidebarLink>)
          }

          <SidebarLink to={"/support-tickets"}>
            <div className="nav-item">
              <i style={{ color: "white", fontSize: 20 }} className="fas fa-ticket-alt" />
              <div className="text">Support Tickets</div>
            </div>
          </SidebarLink>
        </div>
        <div className="web-version">
        Web version {appVersion}
          <div className="copy-right">Copyright&copy;2021 LIWE <br/>
           All Rights Reserved
          </div>
        </div>
      </div>
      
    </div>
  );



  function openInNewTab(url) {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }
}

export function SidebarLink(props: { to?; children; className? }) {
  let active = "";
  const location = useLocation();

  if (location.pathname == props.to) {
    active = "active";
  }

  return (
    <Link to={props.to} className={"nav-link " + active + ` ${props.className}`}>
      {props.children}
    </Link>
  );
}
