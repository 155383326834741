import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Success from "../../assets/images/icons/success.svg";

interface Props {
  open: boolean;
  handleClose: () => void;
}

function SelfPopMessage({ open, handleClose }: Props) {
  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ borderRadius: 10 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 32,
        }}
      >
        <div>
          <img src={Success} />
        </div>
        <div>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DialogContentText
              style={{ fontSize: 36, fontWeight: 700, color: "#404757" }}
            >
              Success!
            </DialogContentText>
            <DialogContentText
              style={{ fontSize: 24, fontWeight: 500, color: "#404757" }}
            >
              Your Condominium Registration is Complete
            </DialogContentText>
            <DialogContentText
              style={{
                fontSize: 16,
                fontWeight: 500,
                color: "#404757",
                textAlign: "center",
              }}
            >
              Thank you for choosing our platform to manage your condominium. If
              you have any questions or need assistance, our support team is
              here to help.
            </DialogContentText>
          </DialogContent>
        </div>
        <div>
          <DialogActions>
            <Button
              variant="text"
              style={{ color: "#b45309" }}
              component="a"
              href="https://liwecommunities.com/"
              target="_self"
            >
              Back
            </Button>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
}

export default SelfPopMessage;
