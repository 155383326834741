import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppButton from "../../components/AppButton/AppButton";
import AppLoading from "../../components/AppLoading/AppLoading";
import Navbar from "../../components/Navbar/Navbar";
import ApiService from "../../services/ApiService";
import { showNotification } from "../../services/HelperMethods";

import "./SettingsPayment.scss";

export default function UntitledSeetings() {

  const apiService = new ApiService();

  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [primaryContactFirstName, setPrimaryContactFirstName] = useState("");
  const [primaryContactLastName, setPrimaryContactLastName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryContact, setPrimaryContact] = useState("");

  const [securityEmail1, setSecurityEmail1] = useState("");
  const [securityEmail2, setSecurityEmail2] = useState("");
  const [securityEmail3, setSecurityEmail3] = useState("");

  const [securityContactNumber1, setSecurityContactNumber1] = useState("");
  const [securityContactNumber2, setSecurityContactNumber2] = useState("");
  const [securityContactNumber3, setSecurityContactNumber3] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    get()
  }, [])

  function get() {

    setIsLoading(true)

    apiService.getCurrentBuildingDetails().then((result) => {

      let data = result.data;

      setName(data?.buildingName);
      setAddressLine1(data?.addressLine1);
      setAddressLine2(data?.addressLine2);
      setStreet(data?.street);
      setCity(data?.city);
      setPostalCode(data?.postal);

      setPrimaryContactFirstName(data?.primaryContactFirstName);
      setPrimaryContactLastName(data?.primaryContactLastName);
      setPrimaryEmail(data?.primaryContactEmail);
      setPrimaryContact(data?.primaryContactMobile);

      setSecurityEmail1(data?.securityEmail);
      setSecurityEmail2(data?.securityEmail2);
      setSecurityEmail3(data?.securityEmail3);

      setSecurityContactNumber1(data?.securityContactNumber);
      setSecurityContactNumber2(data?.securityContactNumber2);
      setSecurityContactNumber3(data?.securityContactNumber3);

      setIsLoading(false)
    }).catch((err) => {
      console.log(err)
    })

    setIsLoading(false)

  }

  function save() {

    setIsLoading(true)

    let data = {
      buildingName: name,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      street: street,
      city: city,
      postal: postalCode,
      primaryContactFirstName: primaryContactFirstName,
      primaryContactLastName: primaryContactLastName,
      primaryContactEmail: primaryEmail,
      primaryContactMobile: primaryContact,
      securityContactNumber: securityContactNumber1,
      securityContactNumber2: securityContactNumber2,
      securityContactNumber3: securityContactNumber3,
      securityEmail: securityEmail1,
      securityEmail2: securityEmail2,
      securityEmail3: securityEmail3
    }

    apiService.updateCurrentBuildingDetails(data).then((result) => {

      let data = result.data;

      if(name==null)
      {
        showNotification("Property Name Empty", "Property Name Empty", "error");
        setIsLoading(false);
        return;
      }
      else if(addressLine1==null || addressLine2==null || street==null || city==null || postalCode==null)
      {
        showNotification("Address is Empty", "Address is Empty", "error");
        setIsLoading(false);
        return;
      }
      else if(primaryContactFirstName==null || primaryContactLastName==null || primaryEmail==null || primaryContact==null)
      {
        showNotification("Primary Contact Details Empty", "Primary Contact Details Empty", "error");
        setIsLoading(false);
        return;
      }
      else if(securityContactNumber1==null || securityContactNumber2==null || securityContactNumber3==null)
      {
        showNotification("Security Contact Numbers is Empty", "Security Contact Numbers is Empty", "error");
        setIsLoading(false);
        return;
      }
      else if(securityEmail1==null || securityEmail2==null|| securityEmail3==null)
      {
        showNotification("Security Email is Empty", "Security Email is Empty", "error");
        setIsLoading(false);
        return;
      }
      if (data?.isSuccessful) {
        showNotification("Property Details Changed", "Property Details Changed", "success");
        get()
      } else {
        showNotification("Unexpected Error", "Unexpected Error", "error");
      }
    })

    setIsLoading(false)
  }

  return (
    <div className="body settings payments">
      <h2>Settings </h2>

      <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
        <Link to="/settings/payment">Payments</Link>
        <Link to="/settings/untitled" className="active">Property</Link>
        <Link to="/settings/support" >Support Tickets</Link>
      </Navbar>

      <div className="white-box mt-4">
        {isLoading
          ? (<AppLoading isLoading={isLoading} />)
          : (<div className="white-box-inner">
            <div>
              <div>
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label>Name</label>
                  </div>
                  <div className="col-auto">
                    <input className="form-control" placeholder="Property Name" value={name}
                      onChange={(e) => { setName(e.target.value); }} />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="sub-title">Address<div>
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label>Address Line 1</label>
                  </div>
                  <div className="col-auto pr-0">
                    <input className="form-control" placeholder="Address Line 1" value={addressLine1}
                      onChange={(e) => { setAddressLine1(e.target.value); }} />
                  </div>
                </div>
              </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Address Line 2</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="Address Line 2" value={addressLine2}
                        onChange={(e) => { setAddressLine2(e.target.value); }} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Street</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="Street" value={street}
                        onChange={(e) => { setStreet(e.target.value); }} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>City</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="City" value={city}
                        onChange={(e) => { setCity(e.target.value); }} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Postal Code</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="Postal Code" value={postalCode}
                        onChange={(e) => { setPostalCode(e.target.value); }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="sub-title">Primary Contact Details<div>
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label> Primary Contact First Name</label>
                  </div>
                  <div className="col-auto pr-0">
                    <input className="form-control" placeholder="Primary Contact First Name" value={primaryContactFirstName}
                      onChange={(e) => { setPrimaryContactFirstName(e.target.value); }} />
                  </div>
                </div>
              </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Primary Contact Last Name </label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="Primary Contact Last Name" value={primaryContactLastName}
                        onChange={(e) => { setPrimaryContactLastName(e.target.value); }} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Primary Contact Email</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="Primary Contact Email" value={primaryEmail}
                        onChange={(e) => { setPrimaryEmail(e.target.value); }} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Primary Contact Phone Number</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="77 XXX XXXX" value={primaryContact}
                        onChange={(e) => { setPrimaryContact(e.target.value); }} maxLength={9} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="sub-title">Security Contact Numbers<div>
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label>Security Contact Number 1</label>
                  </div>
                  <div className="col-auto pr-0">
                    <input className="form-control" placeholder="77 XXX XXXX" value={securityContactNumber1}
                      onChange={(e) => { setSecurityContactNumber1(e.target.value); }} maxLength={9} />
                  </div>
                </div>
              </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Security Contact Number 2</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="77 XXX XXXX" value={securityContactNumber2}
                        onChange={(e) => { setSecurityContactNumber2(e.target.value); }} maxLength={9} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Security Contact Number 3</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="77 XXX XXXX" value={securityContactNumber3}
                        onChange={(e) => { setSecurityContactNumber3(e.target.value); }} maxLength={9} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              <div className="sub-title">Security Emails<div>
                <div className="row align-items-center mt-1">
                  <div className="col-auto">
                    <label> Security Email 1</label>
                  </div>
                  <div className="col-auto pr-0">
                    <input className="form-control" placeholder="Security Email 1" value={securityEmail1}
                      onChange={(e) => { setSecurityEmail1(e.target.value); }} />
                  </div>
                </div>
              </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Security Email 2</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="Security Email 2" value={securityEmail2}
                        onChange={(e) => { setSecurityEmail2(e.target.value); }} />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row align-items-center mt-1">
                    <div className="col-auto">
                      <label>Security Email 3</label>
                    </div>
                    <div className="col-auto pr-0">
                      <input className="form-control" placeholder="Security Email 3" value={securityEmail3}
                        onChange={(e) => { setSecurityEmail3(e.target.value); }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="row align-items-center justify-content-start">
                <div className="col-auto pr-0" style={{ minWidth: 150 }}>
                  <AppButton isLoading={isLoading} onClick={() => { save() }}>Save</AppButton>
                </div>
              </div>
            </div>
          </div>)
        }
      </div>
    </div>
  );
}
