import React, { useEffect, useState } from 'react';
import { Drawer, Form, Input, Button, Row, Col } from 'antd';
import ApiService from "../../services/ApiService";
import  { handleErrors, showNotification, roundOff, handleLoginErrorNavigation } from "../../services/HelperMethods";
import { useHistory } from 'react-router-dom';
import AppButton from '../AppButton/AppButton';
import { Link } from "react-router-dom";
interface AddUserDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const AddUserDrawer: React.FC<AddUserDrawerProps> = ({ visible, onClose }) => {
  const history = useHistory();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [countryCode, setCountryCode] = useState('+94');
  const [validationErrors, setValidationErrors] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [secureTextEntry, setSecureTextEntry] = useState(true);


  const apiService = new ApiService();

  
  const handleSignUp = async () => {
    setIsLoading(true);

    try {
      var response = await apiService.signUp({
        firstName,
        lastName,
        userEmail: email,
        password,
        countryCode,
        mobileNumber: phoneNumber,
      });




      console.log("response.data?.userId", response.data?.userId)
      await navigate(response.data?.userId);
    } catch (error) {
      setValidationErrors(error);
      handleErrors(error);
    }

    setIsLoading(false);
  };

  const navigate = async (userId) => {
    try {
      // await apiService.appAuth({
      //   username: email,
      //   pw: password,
      // });
      onClose();

      history.push('/select-apartment/'+userId, { navigateAfter: '/dashboard' });
    } catch (error) {
      handleLoginErrorNavigation(error, email, history);
    }
  };

  return (
    <Drawer
      title="Add New Residents Users"
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      key="right"
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input
                className="form-control"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                // error={getValidationError(validationErrors, 'FirstName')}
                placeholder="Enter First Name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[{ required: true, message: 'Please input your last name!' }]}
            >
              <Input
                className="form-control"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                // error={getValidationError(validationErrors, 'LastName')}
                placeholder="Enter Last Name"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            { required: true, message: 'Please input your email address!' },
            { type: 'email', message: 'Please enter a valid email address!' },
          ]}
        >
          <Input
            className="form-control"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // error={getValidationError(validationErrors, 'UserEmail')}
            placeholder="Enter Email Address"
          />
        </Form.Item>

        <Form.Item
          label="Mobile Number"
          name="phoneNumber"
          rules={[{ required: true, message: 'Please input your mobile number!' }]}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              className="form-control"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              style={{ width: '20%' }}
            />
            <Input
              className="form-control"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              style={{ width: '80%' }}
              maxLength={9}
            />
          </div>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password
          
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            
            placeholder="Enter password"
            iconRender={(visible) =>
              visible ? (
                <Button type="text" onClick={() => setSecureTextEntry(!secureTextEntry)}>
                  Show
                </Button>
              ) : (
                <Button type="text" onClick={() => setSecureTextEntry(!secureTextEntry)}>
                  Hide
                </Button>
              )
            }
          />
        </Form.Item>

        <p style={{ fontSize: 10, color: '#E91E63' }}>
          Password must contain at least one capital letter, one number, one special character and minimum 8 characters
        </p>

        <Form.Item>
          <AppButton style={{
            background:"primary",
            width: '100%'
          }} 
            onClick={handleSignUp}
          >
            Add User
          </AppButton>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddUserDrawer;