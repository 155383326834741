import React, { useContext, useEffect, useState } from "react";
import ApiService from "../../services/ApiService";
import {
    getDeviceCode,
    handleErrors,
    handleLoginErrorNavigation,
    saveToken,
    showNotification,
} from "../../services/HelperMethods";
import { Link, useHistory, useParams } from "react-router-dom";
import AppButton from "../../components/AppButton/AppButton";
import AppPasswordField from "../../components/AppPasswordField/AppPasswordFiled";

export default function ResetPassword() {
    const [confirmPassword, setConfirmPassword] = useState("");
    const [password, setPassword] = useState("");
    const apiService = new ApiService();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isReset, setIsReset] = useState(false);
    const { token } = useParams() as any;

    return (
        <div className="section login-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="body">
                            <form>
                                <div className="login-box">
                                    <p className="title">Reset Password</p>
                                    {/* <p className="sub-title mb-4">
                                        Forgot password! Not a problem. We'll
                                        send you a reset link.
                                    </p> */}
                                    {isReset ? (
                                        <div>Success</div>
                                    ) : (
                                        <div>
                                            <input
                                                type="password"
                                                onChange={(e) => {
                                                    setConfirmPassword(
                                                        e.target.value
                                                    );
                                                }}
                                                value={confirmPassword}
                                                placeholder="New Password"
                                            />
                                            <AppPasswordField value={password} setter={setPassword} placeholder={"Confirm Password"} />
                                            <AppButton
                                                onClick={handleReset}
                                                isLoading={isLoading}
                                            >
                                                Reset
                                            </AppButton>
                                        </div>
                                    )}

                                    <Link to="/start/login" className="small">
                                        Go back to login page
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    function clearFields() {
        setConfirmPassword("");
        setPassword("");
    }

    async function handleReset() {
        setIsLoading(true);
        try {
            if(password != confirmPassword){
                clearFields();
                setIsLoading(false);
                return showNotification(
                    "Error",
                    "Password Does Not Match",
                    "error"
                  );
            }
            let result = (await apiService.resetPassword({
                token: token,
                newPassword: password,
            })) as any;
            showNotification(
                "Success",
                "Your password changed successfully",
                "success"
            );
            setIsReset(true);
            // history.push("/start/login");
        } catch (error) {
            handleErrors(error);
            clearFields();
        }
        setIsLoading(false);
    }
}
