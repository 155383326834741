import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";

import "./CompoundSecurity.scss";

export default function SecurityRoster() {
    return (
        <div className="body compound-security">

            <h2>Compound</h2>

            <Navbar hideSettings={true} hideAdd={true} hideFilter={true}>
                <Link to="/compound-security">Event Log</Link>
                <Link className="active">Staff Roster</Link>
            </Navbar>

            <div style={{ fontSize: "2rem", textAlign: "center", color: "#8acb87" }}>Coming Soon</div>
        </div>
    );
}
