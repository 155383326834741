import React, { useEffect, useState } from "react";
import AppLoading from "../../components/AppLoading/AppLoading";
import ApiService from "../../services/ApiService";
import moment from "moment";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Modal } from "react-bootstrap";
import { Fragment } from "react";
import AppButton from "../../components/AppButton/AppButton";
import { handleErrors, showNotification } from "../../services/HelperMethods";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import PublicNoticeBox from "../../components/PublicNoticBox/PublicNoticeBox";

import "./PublicNotices.scss";

import FileInput from "../../components/FileInput/FileInput";
import NoticeFileInput from "../../components/FileInput/NoticeFileInput";
import FirebaseServices from "../../services/FirebaseServices";

interface ImageState {
    url: string;
    attachmentType: string;
}

function PublicNotices() {

    const apiService = new ApiService();
    const firebaseServices = new FirebaseServices();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [attachments, setAttachments] = useState([] as any);
    const [otherImages, setOtherImages] = useState<ImageState[]>([] as any[]);
    const [issuedDate, setIssuedDate] = useState(moment());
    const [notices, setNotices] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAddNotice, setShowAddNotice] = useState(false);
    const [selectedNotice, setSelectedNotice] = useState(null as any);
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        getNotices();
    }, []);

    async function getNotices() {

        setIsLoading(true);

        try {
            let result = await apiService.getPublicNotices();
            console.log(result.data.notices, "result.data.notices");

            setNotices(result.data.notices.filter((notice) => {
                return notice?.publishedStatus == "Active"
            }));
        } catch (error) {
            handleErrors(error);
        }

        setIsLoading(false);
    }

    async function handleEdit() {

        setIsLoading(true);

        let data = {
            id: selectedNotice?.id,
            title: title,
            description: description
        }

        await apiService.editNotice(data).then((result) => {
            let data = result.data

            if (data?.isSuccessful) {
                showNotification("Successfully Edited", "Successfully Edited", "success")
            }
        }).catch((error) => {
            showNotification("Unexcepted Error", "Unexcepted Error", "error")
        })

        setIsEditable(false)
        setTitle("")
        setDescription("")
        setIsLoading(false);
        setSelectedNotice(null)

        getNotices();
    }

    function editable() {
        setIsEditable(true)
        setTitle(selectedNotice?.name)
        setDescription(selectedNotice?.description)
    }

    return (
        <div className="body notices-wrapper">
            <AppLoading isLoading={isLoading} />
            <h2>Notices</h2>

            <Navbar hideSettings={true} hideFilter={true}
                onAddButtonClick={() => { setShowAddNotice(true); setTitle(""); setDescription(""); }}>
                <Link className="active" to="/notices/public">Public Notices</Link>
                <Link to="/notices/archived">Archived</Link>
            </Navbar>

            <div className="row">
                {notices.map((notice, i) => {
                    return (
                        <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }} className="col-6 cursor-pointer" onClick={() => { setSelectedNotice(notice); }} key={i}>
                            <PublicNoticeBox notice={notice} />
                        </div>
                    );
                })}
            </div>

            <Modal centered onHide={() => { setSelectedNotice(null); setIsEditable(false) }} show={selectedNotice != null}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {isEditable
                                ? (<input className="form-control" onChange={(e) => { setTitle(e.target.value); }} value={title} />)
                                : (<span>{selectedNotice?.name}</span>)
                            }

                            <span style={{ fontSize: 13, color: "gray", }}>
                                {selectedNotice?.createdBy}{" "}
                                {moment.utc(selectedNotice?.issuedDate).local().format("LLL")}
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <p className="m-des-hd">Description</p>
                        <div>
                            {isEditable
                                ? (<textarea rows={5} className="form-control" onChange={(e) => { setDescription(e.target.value); }}
                                    value={description} />)
                                : (<p className="m-des">{selectedNotice?.description}</p>)
                            }
                        </div>
                    </div>
                    <div className="wrapper-bottom-set">
                        {selectedNotice?.attachments?.map((attachment, index) => {
                            return (
                                <div key={index} className="other-image-wrap" onClick={() => { window.open(attachment.url) }}
                                    style={{ cursor: "pointer" }}>
                                    <img src={attachment.attachmentType == "Image" ? attachment.url : "/images/PDF.png"} />
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className="mt-4 text-right w-100">
                        <AppButton onClick={() => { isEditable ? (handleEdit()) : (editable()) }}
                            style={{ width: 100, padding: 5, backgroundColor: "#4bbb46", color: "white", marginRight: "0.6rem" }}>
                            {isEditable ? ("Save") : ("Edit")}
                        </AppButton>
                        <AppButton onClick={() => { handleArchive() }}
                            style={{ width: 100, padding: 5, backgroundColor: "#FEBE4D", color: "white", }}>
                            Archive
                        </AppButton>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showAddNotice} onHide={() => { setShowAddNotice(false); setOtherImages([]) }}>
                <Modal.Header closeButton>
                    <Modal.Title>New Public Notice</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="form-group">
                        <label>Title</label>
                        <input className="form-control" onChange={(e) => { setTitle(e.target.value); }} value={title} />
                    </div>
                    <label>Description</label>
                    <textarea rows={5} className="form-control" onChange={(e) => { setDescription(e.target.value); }} value={description} />

                    <div className="wrapper-bottom-set">
                        {otherImages?.map((img, i) => {
                            return (
                                <div key={i} className="other-image-wrap">
                                    <button onClick={() => {
                                        let modified = [...otherImages].filter((_v, oIndex) => { return oIndex != i; });
                                        setOtherImages(modified);
                                    }} className="remove-button">
                                        <i className="fas fa-times" />
                                    </button>
                                    <img src={img.attachmentType == "Image" ? img.url : "/images/PDF.png"} />
                                </div>
                            );
                        })}
                        <div className="other-image-add" style={{ position: "relative" }}>
                            <NoticeFileInput onChange={async (url) => {

                                let otherImageArr = [...otherImages];

                                var docURL = await firebaseServices.uploadDoc(url)

                                if (docURL) {
                                    if (url.substring(5, 6) == "a") {
                                        otherImageArr.push({ url: docURL, attachmentType: "PDF" });
                                    } else {
                                        otherImageArr.push({ url: docURL, attachmentType: "Image" });
                                    }
                                }

                                setOtherImages(otherImageArr);
                            }} />
                            <span className="other-image-upload-icon">
                                <i className="fas fa-plus" />
                            </span>
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <AppButton isLoading={isLoading} onClick={handleSubmit}>
                        Save &amp; Submit
                    </AppButton>
                </Modal.Footer>
            </Modal>
        </div >
    );

    async function handleArchive() {

        setIsLoading(true);

        try {

            let data = {
                noticeId: selectedNotice?.id,
            };

            await apiService.archiveNotice(data);

            setIsEditable(false)
            setTitle("")
            setDescription("")
            setIsLoading(false);
            setSelectedNotice(null)

            getNotices();
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function handleSubmit() {

        setIsLoading(true);

        try {
            let data = {
                name: title,
                description: description,
                issuedDate: issuedDate,
                attachments: otherImages
            };

            await apiService.createNotice(data);

            getNotices();

            setAttachments([])
            setOtherImages([])
            setShowAddNotice(false);
        } catch (error) {
            handleErrors(error);
        }

        setIsLoading(false);
    }
}
export default  PublicNotices;