import "./Feedback.scss";
import { Link } from "react-router-dom";

import React from "react";
import { Table } from "react-bootstrap";
import AddIcon from "../../assets/images/icons/add.svg";
import GearIcon from "../../assets/images/icons/gear.svg";
import Navbar from "../../components/Navbar/Navbar";

export default function Feedback() {
    return (
        <div className="body">
            <h1>Feedback</h1>

            <Navbar>
                <Link className="active" to="#staff">
                    Support Tickets
                </Link>
                <Link to="#services">Feedback</Link>
            </Navbar>

            <Table responsive="lg" borderless={true} id="feedback-table">
                <thead>
                    <tr>
                        <th>Subject &#38; Category</th>
                        <th>Sent By</th>
                        <th>Date Created</th>
                        <th>Assignee</th>
                        <th>Description</th>
                        <th></th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Security</td>
                        <td>Abraham Pigeon</td>
                        <td>48 Min ago</td>
                        <td>-</td>
                        <td>Ullamcorper a lacus vestibulum</td>
                        <td className="font-blue">View</td>
                        <td className="font-blue">Open</td>
                    </tr>
                    <tr>
                        <td>Facilities</td>
                        <td>Douglas Lyphe</td>
                        <td>2 Hours ago</td>
                        <td>-</td>
                        <td>Pretium quam vulputate</td>
                        <td className="font-blue">View</td>
                        <td className="font-yellow">Pending</td>
                    </tr>
                    <tr>
                        <td>Shopping</td>
                        <td>Ursula Gurnmeister</td>
                        <td>8 Hours ago</td>
                        <td>-</td>
                        <td>-</td>
                        <td className="font-blue">View</td>
                        <td className="font-yellow">Pending</td>
                    </tr>
                    <tr>
                        <td>Water Service</td>
                        <td>Ingredia Nutrisha</td>
                        <td>18 Aug 2020</td>
                        <td>-</td>
                        <td>Nisl suscipit adipiscing bibendum</td>
                        <td className="font-blue">View</td>
                        <td className="font-blue">Open</td>
                    </tr>

                    <tr>
                        <td>Security</td>
                        <td>Abraham Pigeon</td>
                        <td>48 Min ago</td>
                        <td>-</td>
                        <td>Ullamcorper a lacus vestibulum</td>
                        <td className="font-blue">View</td>
                        <td className="font-blue">Open</td>
                    </tr>
                    <tr>
                        <td>Facilities</td>
                        <td>Douglas Lyphe</td>
                        <td>2 Hours ago</td>
                        <td>-</td>
                        <td>Pretium quam vulputate</td>
                        <td className="font-blue">View</td>
                        <td className="font-yellow">Pending</td>
                    </tr>
                    <tr>
                        <td>Shopping</td>
                        <td>Ursula Gurnmeister</td>
                        <td>8 Hours ago</td>
                        <td>-</td>
                        <td>-</td>
                        <td className="font-blue">View</td>
                        <td className="font-yellow">Pending</td>
                    </tr>
                    <tr>
                        <td>Water Service</td>
                        <td>Ingredia Nutrisha</td>
                        <td>18 Aug 2020</td>
                        <td>-</td>
                        <td>Nisl suscipit adipiscing bibendum</td>
                        <td className="font-blue">View</td>
                        <td className="font-blue">Open</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
}
