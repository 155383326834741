import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AppButton from "../../../components/AppButton/AppButton";
import AppLoading from "../../../components/AppLoading/AppLoading";
import ApiService from "../../../services/ApiService";
import { handleErrors, showNotification } from "../../../services/HelperMethods";

export default function () {

    const [isLoading, setIsLoading] = useState(false);
    const [condominiums, setCondominiums] = useState([] as any);

    const [condominiumName, setCondominiumName] = useState("");
    const [condominiumCity, setCondominiumCity] = useState("");
    const [condominiumBankName, setCondominiumBankName] = useState("");
    const [condominiumBankAccount, setCondominiumBankAccount] = useState("");
    const apiService = new ApiService();

    useEffect(() => {
        getCondominiums();
    }, [])


    return (
        <div>
            {addCondoUI()}
            {viewCondoUI()}
        </div>
    )


    async function removeCondo(id: any) {
        setIsLoading(true);
        try {
            await apiService.removeCondominiumsAdmin(id)
            getCondominiums();
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }


    function viewCondoUI() {
        return (<div>
            <Table responsive="lg" borderless={true}>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>City</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                    {(condominiums.map((item, i) => {
                        return (
                            <tr className="cursor-pointer" key={i}>
                                <td>{item.name}</td>
                                <td>{item.city}</td>
                                <td>
                                    <div className="row">
                                        <div className="col-auto">
                                            <AppButton isLoading={isLoading} variant="danger" onClick={() => { removeCondo(item.id) }}>Delete</AppButton>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                    }))}
                </tbody>
            </Table>
        </div>)
    }


    function addCondoUI() {
        return (
            <div className="card mt-3">
                <div className="card-header">
                    Add New Condominium
                </div>
                <div className="card-body">
                    <div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Name</label>
                            <div>
                                <input className="form-control" type="text" value={condominiumName}
                                    onChange={(e) => { setCondominiumName(e.target.value); }} placeholder="Name" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">City</label>
                            <div>

                                <input className="form-control" type="text" value={condominiumCity}
                                    onChange={(e) => { setCondominiumCity(e.target.value); }} placeholder="City" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Bank Name</label>
                            <div>
                                <input className="form-control" type="text" value={condominiumBankName}
                                    onChange={(e) => { setCondominiumBankName(e.target.value); }} placeholder="Bank Name" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Account Number</label>
                            <div>
                                <input className="form-control" type="text" value={condominiumBankAccount}
                                    onChange={(e) => { setCondominiumBankAccount(e.target.value); }} placeholder="Bank Account" />
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        {/* <div className="col-auto">
                            <AppButton type="danger" onClick={() => { }}>Remove Condominium</AppButton>
                        </div> */}
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="row">
                            <div className="col-auto align-self-end">
                                <AppButton onClick={() => { addCondominium() }}>Save Condominium</AppButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    function addCondominium() {
        setIsLoading(true);
        let data = {
            name: condominiumName,
            city: condominiumCity,
            bankName: condominiumBankName,
            bankAccountNumber: condominiumBankAccount
        }
        apiService.addCondominiumAdmin(data).then((result) => {

            let data = result.data

            if (data?.isSuccessful) {
                showNotification("Condominium Added Successfully", "Condominium Added Successfully", "success")

                setCondominiumName("");
                setCondominiumCity("");
                setCondominiumBankName("");
                setCondominiumBankAccount("");
            } else {
                showNotification("Error Adding Condominium", "Error Adding Condominium", "error")
            }
            getCondominiums();
        }).catch((err) => {
            handleErrors(err);
        })
        setIsLoading(false);
    }

    function getCondominiums() {
        setIsLoading(true);
        apiService.getCondominiumsAdmin().then((result) => {

            let data = result.data
            if (data?.isSuccessful) {
                setCondominiums(data?.buildings)
            }
        }).catch((err) => {
            handleErrors(err);
        })
        setIsLoading(false);
    }
}