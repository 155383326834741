import React, { useEffect, useState } from "react";
import { handleErrors } from "../../services/HelperMethods";
import ApiService from "../../services/ApiService";
import AppLoading from "../../components/AppLoading/AppLoading";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Fragment } from "react";

import "./ManageFacilities.scss";

export default function ManageFacilities() {

    const apiService = new ApiService();

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [amenities, setAmenities] = useState([] as any[]);

    useEffect(() => {
        getFacilities();
    }, []);

    async function getFacilities() {
        setIsLoading(true);
        try {
            let result = (await apiService.getAmenities()) as any;
            result = result.data;

            setAmenities(result.amenities);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    return (
        <div className="body">
            <AppLoading isLoading={isLoading} />
            <h2>Facilities</h2>

            <Navbar hideSettings={true} onAddButtonClick={() => { history.push("/facility/add"); }} hideFilter={true}>
                <Link to="/facility/bookings">Bookings</Link>
                <Link to="/facility/manage" className="active">Manage Facilities</Link>
            </Navbar>

            <div className="facilities-items-wrap">
                {amenities.map((item) => {
                    return (
                        <Link key={item.id} style={{ backgroundImage: `url(${item.imageUrl})`, }}
                            to={"/facility/add/" + item.id} className="box d-flex justify-content-center align-items-center">
                            <div className="background-black" />
                            <span style={{ zIndex: 2 }}>{item.name}</span>
                            {!item.active
                                ? (<div className="inactive">Inactive</div>) : (<Fragment />)
                            }
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}
