import React, { useContext, useEffect, useState } from "react";
import AppButton from "../../../components/AppButton/AppButton";
import TickCircle from "../../../components/TickCircle/TickCircle";
import ApiService from "../../../services/ApiService";
import { showNotification } from "../../../services/HelperMethods";
import { AdminSelectedCondoIdContext } from "../../../store/Store";
import AdminCondoSelectTop from "../AdminComponents/AdminCondoSelectTop";

 function Admin() {

  const apiService = new ApiService();

  const [selectedCondoId] = useContext(AdminSelectedCondoIdContext);

  const [password, setPassword] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [condominiums, setCondominiums] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [userApartmentStatus, setApartmentStatus] = useState(null as any);

  useEffect(() => {
    if (selectedCondoId) {
      getData();
    }
  }, [selectedCondoId])

  async function getData() {
    await apiService.adminDashboard(selectedCondoId).then((result) => {
      var data = result.data;
      setApartmentStatus(data);
    });
  }

  return (
    <div className="card" style={{ maxWidth: 800 }}>
      <div className="card-body">
        <div className="statistics-wrap">
          <div className="title-wrap">
            <h4>Condo Statistics</h4>
          </div>
          <div className="d-flex flex-row justify-content-between condo-section">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="condo-icons occupied d-flex justify-content-center align-items-center">
                <TickCircle large />
              </div>

              <div className="d-flex flex-column mr-2">
                <span className="font-dark-grey condo-stats-heading">
                  Occupied
                </span>
                <span className="font-dark-grey condo-stats-amount">
                  {userApartmentStatus?.totalOccupied}
                </span>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="condo-icons vacant d-flex justify-content-center align-items-center">
                <TickCircle icon="minus" large />
              </div>

              <div className="d-flex flex-column mr-2">
                <span className="font-dark-grey condo-stats-heading">
                  Vacant
                </span>
                <span className="font-dark-grey condo-stats-amount">
                  {userApartmentStatus?.totalVacant}
                </span>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-center align-items-center">
              <div className="condo-icons users d-flex justify-content-center align-items-center">
                <TickCircle icon="user" large />
              </div>

              <div className="d-flex flex-column mr-2">
                <span className="font-dark-grey condo-stats-heading">
                  Users
                </span>
                <span className="font-dark-grey condo-stats-amount">
                  {userApartmentStatus?.totalUsers}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}
export default Admin;
