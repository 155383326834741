import { useState, useEffect, useContext, Fragment } from "react";
import { Table } from "react-bootstrap";
import ApiService from "../../../services/ApiService";
import { handleErrors, showNotification } from "../../../services/HelperMethods";
import { AdminSelectedCondoIdContext, UserDetailsContext } from "../../../store/Store";

export default function () {
    const [condominiums, setCondominiums] = useState([] as any[]);
    const [selected, setSelected] = useContext(AdminSelectedCondoIdContext);
    const apiService = new ApiService();
    const [user] = useContext(UserDetailsContext);

    useEffect(() => {
        if (user) {
            getCondominiums();
        }
    }, [user])

    function getCondominiums() {
        apiService.getCondominiumsAdmin().then((result) => {
            let data = result.data
            if (data?.isSuccessful) {
                setCondominiums(data?.buildings)
            }
        }).catch((err) => {
            handleErrors(err);
        })
    }

    function changeCondominium(e) {
        setSelected(parseInt(e.target.value));
    }

    if (!user || user?.userType != "SystemAdmin") {
        return <Fragment />
    }

    return <div className="select-condo">
        <select className="form-control"
            onChange={(e) => { changeCondominium(e) }}
            value={selected}>
            <option value={0}>Select Condominium</option>
            {condominiums?.map((condominium, index) => {
                return (
                    <option key={index} value={condominium?.id}>{condominium?.name}</option>
                )
            })}
        </select>
    </div>
}