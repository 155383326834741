import { useEffect, useState } from "react";
import AppButton from "../../components/AppButton/AppButton";
import ApiService from "../../services/ApiService";
import { handleErrors, showNotification } from "../../services/HelperMethods";
import LiweIcon from "../../assets/images/liwe.png";
import Building from "../../assets/images/icons/building.svg";
import "./Style.scss";
import SelfPopMessage from "./SelfPopMessage";
import { NavLink } from "react-router-dom";

function ManagementSelfReg() {
  const apiService = new ApiService();

  const [building, setBuilding] = useState("");
  const [newBuilding, setNewBuilding] = useState("");
  const [checked, setChecked] = useState(false);
  const [result, setResult] = useState(false);
  const [open, setOpen] = useState(false);
  const [agree, setAgree] = useState(false);

  const [reqPlanNumber, setReqPlanNumber] = useState("");
  const [reqUserName, setReqUserName] = useState("");
  const [reqAddress, setReqAddress] = useState("");
  const [reqContact, setReqContact] = useState("");
  const [reqEmail, setReqEmail] = useState("");

  const [unRegBuildings, setUnRegBuildings] = useState([]);

  const [emailError, setEmailError] = useState(false);
  const [contactError, setContactError] = useState(false);

  useEffect(() => {
    getAllUnRegBuildings();
  }, []);

  function validateEmail(email) {
    const regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!regex.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  }

  function validateContactNumber(number) {
    if (number.length !== 9) {
      setContactError(true);
    } else {
      setContactError(false);
    }
  }

  async function getAllUnRegBuildings() {
    await apiService
      .GetAllUnRegBuilding()
      .then((response) => {
        setResult(response.data?.isSuccess);
        if (!response.data?.isSuccess) {
          setChecked(true);
        }
        setUnRegBuildings(response.data?.buildingsName);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  }

  function onChangeValue() {
    setChecked(!checked);
  }

  function onChangeAgree() {
    setAgree(!agree);
  }

  function handleBuildingTextInput(event) {
    setNewBuilding(event.target.value);
  }

  function handleSelect(event) {
    setBuilding(event.target.value);
  }

  function handlePlanNumber(event) {
    setReqPlanNumber(event.target.value);
  }

  function handleUserName(event) {
    setReqUserName(event.target.value);
  }

  function handleUserEmail(event) {
    setReqEmail(event.target.value);
    validateEmail(event.target.value);
  }

  function handleAddress(event) {
    setReqAddress(event.target.value);
  }

  function handleContact(event) {
    if (/^\d{0,9}$/.test(event.target.value)) {
      setReqContact(event.target.value);
      validateContactNumber(event.target.value);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  async function handleSubmit() {
    if (emailError)
      return showNotification("Infromation", "Invalid Email", "info");

    if (contactError)
      return showNotification("Infromation", "Invalid Contact Number", "info");

    if (!agree)
      return showNotification("Infromation", "Please Select Agree", "info");

    let data = {
      name: checked ? newBuilding : building,
      userName: reqUserName,
      userEmail: reqEmail,
      contactNumber: "0" + reqContact,
      address: reqAddress,
      planNumber: reqPlanNumber,
    };

    await apiService
      .signUpBuilding(data)
      .then((response) => {
        setUnRegBuildings(unRegBuildings?.filter((item) => item !== building));
        if (response.data?.isSuccess) setOpen(true);
      })
      .catch((error) => {
        handleErrors(error);
      });

    clearFields();
  }

  function clearFields() {
    setBuilding("");
    setNewBuilding("");
    setReqPlanNumber("");
    setReqAddress("");
    setReqContact("");
    setReqUserName("");
    setReqEmail("");
  }

  return (
    <div className="frame">
      <div className="container-left">
        <div className="company">
          <div>
            <img className="company-logo" src={LiweIcon} />
          </div>
          <div className="frame1">
            <div className="frame1-text1">Register with us today!</div>
            <div className="frame1-text2">Let’s get started</div>
          </div>
        </div>
        <div className="building-img">
          <img src={Building} />
        </div>
      </div>

      {/* right */}
      <div className="container-right">
        <div className="frame2">
          {/* heading */}
          <div>
            <div className="frame2-text1">Condominium Registration</div>
            <div className="frame2-text2">
              To complete your registration, kindly fill in the required fields
              below.
            </div>
          </div>

          {/* input fields */}
          <div className="details">
            {/* Condominium Details */}
            <div>
              <div className="detailse-header">Condominium Details</div>
              {/* condo name */}
              <div className="condo-field" style={{ marginTop: "25px" }}>
                <div className="field-name">Condominium Name</div>
                {!checked && result && (
                  <div style={{ marginTop: 5 }}>
                    <select
                      style={{
                        border: "1px solid #D0D5DD",
                        width: "70%",
                        borderRadius: 8,
                        padding: 5,
                      }}
                      className="form-control"
                      onChange={handleSelect}
                      value={building}
                    >
                      <option disabled={true} value="">
                        Select Building
                      </option>
                      {unRegBuildings?.map((building, index) => {
                        return (
                          <option key={index} value={building}>
                            {building}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {/* if can not find building in drop down */}
                <div style={{ marginTop: 20 }}>
                  {result && (
                    <div style={{ display: "flex", gap: 10 }}>
                      <div>
                        <input
                          className="checkbox"
                          type="checkbox"
                          onChange={onChangeValue}
                        />
                      </div>
                      <div>I couldn't find my condominium</div>
                    </div>
                  )}

                  {(checked || !result) && (
                    <div>
                      <input
                        className="building-name"
                        type="text"
                        placeholder="Enter your condominium"
                        value={newBuilding}
                        onChange={handleBuildingTextInput}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* address */}
              <div className="condo-field">
                <div className="ffield-name">
                  Condominium Registered Address
                </div>
                <div style={{ marginTop: 5 }}>
                  <input
                    className="address"
                    type="text"
                    placeholder="Eg: 9/2B, Charlimont road, Colombo 06"
                    value={reqAddress}
                    onChange={handleAddress}
                  />
                </div>
              </div>

              {/* plan number */}
              <div className="condo-field">
                <div className="field-name">Condo Plan Number</div>
                <div style={{ marginTop: 5 }}>
                  <input
                    className="plan-number"
                    type="text"
                    placeholder="XXXXX"
                    value={reqPlanNumber}
                    onChange={handlePlanNumber}
                  />
                </div>
              </div>
            </div>

            {/* personal details */}
            <div className="frame3">
              {/* header */}
              <div className="detailse-header">Contact Person Details</div>

              {/* name */}
              <div className="condo-field">
                <div className="field-name">Contact Person Name</div>
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  <input
                    className="user-name"
                    type="text"
                    placeholder="Full Name"
                    value={reqUserName}
                    onChange={handleUserName}
                  />
                </div>
              </div>

              {/* email */}
              <div className="condo-field">
                <div className="field-name">E-Mail Address</div>
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  <input
                    className="email"
                    type="email"
                    placeholder="Valid email address"
                    value={reqEmail}
                    onChange={handleUserEmail}
                  />
                  {emailError && reqEmail && (
                    <p style={{ color: "red", marginLeft: 5, fontSize: 10 }}>
                      *Invalid Email Address
                    </p>
                  )}
                </div>
              </div>

              {/* number */}
              <div className="condo-field">
                <div className="field-name">Contact Number</div>
                <div style={{ marginTop: 5 }}>
                  <input
                    className="number1-field"
                    type="text"
                    placeholder="+94"
                    readOnly
                  />
                  <input
                    className="number2-field"
                    type="text"
                    value={reqContact}
                    placeholder="7XXXXXXXX"
                    onChange={handleContact}
                  />
                  {contactError && reqContact && (
                    <p style={{ color: "red", marginLeft: 5, fontSize: 10 }}>
                      *Invalid Contact Number
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* terms */}
            <div className="terms">
              <div>
                <input
                  className="checkbox"
                  type="checkbox"
                  onChange={onChangeAgree}
                />
              </div>
              <div className="text1">I confirm that I have read and agree to the </div>
              <NavLink
                className="text2"
                to="/terms-and-conditions"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </NavLink>
              <div className="text1">and</div>
              <NavLink
                className="text2"
                to="/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </NavLink>
            </div>

            <div className="register">
              <AppButton className="register-btn" onClick={handleSubmit}>
                Register Now
              </AppButton>
            </div>
          </div>
        </div>
      </div>
      <SelfPopMessage open={open} handleClose={handleClose} />
    </div>
  );
}

export default ManagementSelfReg;
