import React, {useEffect, useState} from "react";
import {Fragment} from "react";
import {useParams} from "react-router-dom";
import AppLoading from "../../components/AppLoading/AppLoading";
import FileInput from "../../components/FileInput/FileInput";
import ApiService from "../../services/ApiService";
import {TimePicker} from "antd";
import {handleErrors, showNotification, weekList,} from "../../services/HelperMethods";
import Checkbox from "@material-ui/core/Checkbox";
import NumberFormat from "react-number-format";
import {Radio, Switch} from "@material-ui/core";
import {Modal, Table} from "react-bootstrap";
import moment from "moment";
import AppButton from "../../components/AppButton/AppButton";
import ProfileImage from "../../components/ProfileImage/ProfileImage";
import FirebaseServices from "../../services/FirebaseServices";

import { Cropper, getCroppedImg } from "react-cropper-custom";
import "react-cropper-custom/dist/index.css";

import "./AddFacility.scss";

interface ImageState {
  isBase64: boolean;
  url: string;
}

interface AmenityAvailability {
  weekNumber: number;
  times: [{ startDate: moment.Moment; endDate: moment.Moment; }];
}

export default function AddFacility({history}) {

  const apiService = new ApiService();

  const firebaseServices = new FirebaseServices();

  let {id} = useParams() as any;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [mainImage, setMainImage] = useState<ImageState>(null as any);
  const [otherImages, setOtherImages] = useState<ImageState[]>([] as any[]);
  const [availabilityType, setAvailabilityType] = useState(0);
  const [amenityAvailability, setAmenityAvailability] = useState<AmenityAvailability[]>([] as any[]);
  const [minBooking, setMinBooking] = useState(0);
  const [maxBooking, setMaxBooking] = useState(1);
  const [maxOccupancy, setMaxOccupancy] = useState(1);
  const [depositFee, setDepositFee] = useState(0);
  const [hourlyCharge, setHourlyCharge] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [bookingType, setBookingType] = useState(1);
  const [amenity, setAmenity] = useState(null as any);
  const [amenityBooking, setAmenityBooking] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [img, setImg] = useState<string>("");
  const [aspect, setAspect] = useState(1);
  const [cropImg, setCropImg] = useState<string>("");
  
  const [fixedChargeHour,setFixedChargeHour] = useState(1);
  const [fixedChargeAmount, setFixedChargeAmount] = useState(0);
  const {RangePicker} = TimePicker;

  useEffect(() => {
    if (id) {
      handleEdit(id);
    }
  }, []);

  async function handleEdit(id) {

    setIsLoading(true);

    try {
      let result = (await apiService.getAmenity(id)) as any;
      result = result.data;

      let amenity = result.amenity;
      let amenityBooking = result.amenityBookings;

      setAmenity(amenity);
      setAmenityBooking(amenityBooking);
      setMainImage({isBase64: false, url: amenity?.imageUrl});

      let images = [] as ImageState[];

      result.amenity.amenityImages?.forEach((img) => {
        images.push({isBase64: false, url: img.imageUrl});
      });

      setOtherImages(images);
      setName(amenity?.name);

      let amenityAvailability = [] as AmenityAvailability[];
      (result?.amenity.amenityAvailabilities as AmenityAvailability[])?.forEach((item) => {
          item.times.forEach((t) => {
            t.startDate = moment.utc(t.startDate).local();
            t.endDate = moment.utc(t.endDate).local();
          });

          amenityAvailability.push(item);
        }
      );

      setAmenityAvailability(amenityAvailability);
      setDescription(result?.amenity.description);
      setDepositFee(result?.amenity.depositFee);
      setHourlyCharge(Number((result?.amenity.feePerMinute * 60).toFixed(0)));
      setServiceCharge(result?.amenity.serviceChargePercentage);
      setMinBooking(Number((result?.amenity.minBookingTimeMinute / 60).toFixed(2)));
      setMaxBooking(Number((result?.amenity.maxBookingTimeMinute / 60).toFixed(2)));
      setMaxOccupancy(result?.amenity.capacity);
      setAvailabilityType(result?.amenity.amenityAvailabilityStatus);
      setIsActive(result?.amenity.active);
      setBookingType(result.amenity.type);
      setFixedChargeHour(Number(result?.amenity.fixedChargeHour));
      setFixedChargeAmount(Number(result?.amenity.fixedChargeAmount));
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }

  function handleOnClose(){
    setOpen(false);
  }

  function handleOnOpen(){
    setOpen(true);
  }

  function handleFileChange(event: any){
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // If you need to convert the file to a base64 string
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64StringImage = reader.result;
          // Set the mainImage state with the base64 string
          setImg(base64StringImage)
        } else {
          console.error('Failed to convert the file to a base64 string.');
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  async function onCropComplete(croppedArea: any){
    try {
      const image = await getCroppedImg(img, croppedArea, {
        width: 1200,
        height: 1200 * aspect,
      });
      setCropImg(image);
    } catch (e) {
      console.error(e);
    }
  };

  function handleOnSave(){
    setOpen(false);
    if(cropImg){
      setMainImage({isBase64: true, url: cropImg,});
    }else{
      if(img){
        setMainImage({isBase64: true, url: img,});
      }
    }
    setImg("")
    setCropImg("")
  }
  function handleOnCancel(){
    setOpen(false);
    setImg("")
    setCropImg("")
  }

  return (
    <div className="body add-facility-wrapper">
      <AppLoading isLoading={isLoading}/>
      <div style={{paddingBottom: "3rem"}} className="container-fluid">
        <div className="row">
          <div className="col-md-12 afw-top">
            <div className="wrapper-header">

              <p className="title">{name}</p>
              <div style={{
                display: "flex", flexDirection: "row",
                justifyContent: "end", alignItems: "center",
              }}>
                <div className="wrapper-active">
                  <label style={{fontWeight: 500}}>
                    {isActive ? ("Active") : ("Inactive")}
                  </label>
                  <Switch checked={isActive} onChange={(e) => {
                    setIsActive(e.target.checked);
                  }}
                          inputProps={{"aria-label": "secondary checkbox",}}/>
                </div>
                <div style={{width: 350, marginLeft: 50}}>
                  <AppButton isLoading={isLoading} onClick={handleInactive}>
                    {id ? "Update Facility" : "Add Facility"}
                  </AppButton>
                </div>
              </div>
            </div>
          </div>

          <div className="col lft-col">
            <div className="wrapper-images-set">
              <div className="main-image-wrap">
                <span className="upload-icon" style={{cursor: "pointer"}} onClick={handleOnOpen}>
                  <i className="fas fa-camera"/>
                </span>
                <Modal centered onHide={handleOnClose} show={open}>
                  <Modal.Body>
                    <div>
                      {
                        img === "" ?
                        <div style={{
                          width: "100%",
                          maxWidth: "540px",
                          height: "50vh",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderStyle: "dotted",
                        }}>
                          <input 
                          type="file" 
                          accept="image/*" 
                          onChange={handleFileChange}
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            opacity: 0,
                            cursor: "pointer"
                          }}/>
                          upload image
                          
                        </div> :
                        <div
                          className="wrapper"
                          style={{
                            width: "100%",
                            maxWidth: "540px",
                            height: "50vh",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderStyle: "dotted",
                          }}>
                          <Cropper
                            width={300}
                            height={200}
                            src={img}
                            aspect={aspect}
                            onCropComplete={onCropComplete}
                          />
                        </div>
                      }
                    </div>
                    <div className="mt-4 text-right w-100">
                      <AppButton 
                        onClick={handleOnCancel}
                        style={{
                          width: 100, 
                          padding: 5, 
                          backgroundColor: "#FEBE4D", 
                          color: "white",
                          marginRight: "0.6rem"
                        }}>
                          {"Cancel"}
                      </AppButton>
                      <AppButton 
                        onClick={handleOnSave}
                        style={{
                          width: 100,
                          padding: 5,
                          backgroundColor: "#4bbb46",
                          color: "white",
                         }}>
                        {"Save"}
                      </AppButton>
                    </div>
                  </Modal.Body>
                </Modal>
                {mainImage
                  ? (<img src={mainImage.url} className="img-main"/>)
                  : (<Fragment/>)
                }
              </div>
              {/* <div className="wrapper-bottom-set">
                                {otherImages?.map((img, i) => {
                                    return (
                                        <div key={i} className="other-image-wrap">
                                            <button onClick={() => {
                                                let modified = [...otherImages,].filter((v, oIndex) => { return oIndex != i; });
                                                setOtherImages(modified);
                                            }} className="remove-button">
                                                <i className="fas fa-times" />
                                            </button>
                                            <img src={img.url} />
                                        </div>
                                    );
                                })}
                                <div className="other-image-add" style={{ position: "relative" }}>
                                    <FileInput onChange={(url) => {
                                        let otherImageArr = [...otherImages,];

                                        if (url) {
                                            otherImageArr.push({ isBase64: true, url: url, });
                                        }

                                        setOtherImages(otherImageArr);
                                    }} />
                                    <span className="other-image-upload-icon">
                                        <i className="fas fa-plus" />
                                    </span>
                                </div>
                            </div> */}
            </div>

            <div className="wrapper-info">
              <i className="fas fa-info-circle icon-info"/>
              <p className="title">General</p>
              <div className="wrapper-form">
                <label htmlFor="inp" className="inp">
                  <input type="text" placeholder="&nbsp;" value={name}
                         onChange={(e) => {
                           setName(e.target.value);
                         }}/>
                  <span className="label">Name</span>
                </label>

                <label htmlFor="inp" className="inp">
                                    <textarea style={{whiteSpace: "pre-wrap"}} maxLength={250} placeholder="&nbsp;"
                                              value={description}
                                              onChange={(e) => {
                                                setDescription(e.target.value);
                                              }}/>
                  <span className="label">Description</span>
                </label>
              </div>
            </div>
          </div>

          <div className="col rht-col">
            <div className="row">
              <div className="col-md-12">
                <div className="wrapper-title-availability">
                  <i className="fas fa-clock icon-clock"/>
                  <p className="title">Availability</p>
                </div>
              </div>

              <div className="col-auto">
                <div className="wrapper-availability">
                  <div className="wrapper-form">
                    <div className="row">
                      <div className="col-auto">
                        <label className="label-radio">
                          <Radio checked={availabilityType == 0}
                                 onClick={() => {
                                   setAvailabilityType(0);
                                 }}/>
                          Always Open
                        </label>
                      </div>
                      <div className="col-auto">
                        <label className="label-radio">
                          <Radio checked={availabilityType == 1}
                                 onClick={() => {
                                   setAvailabilityType(1);
                                 }}/>
                          Open on Selected Hours
                        </label>
                      </div>
                    </div>
                    <AvailableTime/>
                  </div>
                </div>
              </div>

              <div className="col-auto wrapper-duration-outer">
                <div className="wrapper-duration">
                  <p className="title">
                    Minimum Booking Duration
                  </p>
                  <input type="number" value={minBooking} min={0} max={24}
                         onChange={(e) => {
                           setMinBooking(parseFloat(e.target.value));
                         }}/>
                  <p className="sub-title">Hours</p>
                </div>
                <div className="wrapper-duration">
                  <p className="title">
                    Maximum Booking Duration
                  </p>
                  <input type="number" min={1} max={23} value={maxBooking}
                         onChange={(e) => {
                           setMaxBooking(parseFloat(e.target.value));
                         }}/>
                  <p className="sub-title">Hours</p>
                </div>

                <div className="wrapper-duration sh-ex">
                  <p className="title">Shared/Exclusive</p>
                  <div>
                    <label className="cursor-pointer">
                      Exclusive
                      <Radio checked={bookingType == 1} onClick={() => {
                        setBookingType(1);
                        setMaxOccupancy(1)
                      }}/>
                    </label>
                  </div>
                  <div>
                    <label className="cursor-pointer">
                      Shared
                      <Radio checked={bookingType == 2} onClick={() => {
                        setBookingType(2);
                        setMaxOccupancy(2)
                      }}/>
                    </label>
                  </div>

                  {maxOccupancy > 1 &&
                      <div className="wrapper-duration">
                          <p className="title">Maximum Bookings</p>
                          <input type="number" min={1}
                                 value={maxOccupancy}
                                 onChange={(e) => {
                                   setMaxOccupancy(parseInt(e.target.value));
                                   if (parseInt(e.target.value) < 2) {
                                     setBookingType(1)
                                   }
                                 }}/>
                      </div>
                  }
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="wrapper-title-associated-charges">
                  <i className="fas fa-clock icon-clock"/>
                  <p className="title">Associated charges</p>
                </div>
              </div>

              <div className="col-md-12">
                <div className="wrapper-associated-charges">
                  <div className="wrapper-form">
                    <div className="wrapper-charges">
                      <label className="label-checkbox">
                        Security Deposit (Refundable)
                      </label>

                      <NumberFormat className="input-charge" thousandSeparator={true}
                                    value={depositFee}
                                    onValueChange={(e) => {
                                      setDepositFee(parseFloat(e.value));
                                    }}
                                    min={0}/>

                      <p className="sub-title">LKR</p>
                    </div>
<div className="row mb-2 wrapper-charges">
      <div className="col-md-4">
        <label className="label-checkbox">Facility Charges</label>
      </div>
      <div className="col-md-8" style={{marginTop:"20px",marginBottom:"20px"}}>
        <div style={{marginLeft:"-12px"}}>
          
          <label>Fixed Charge</label>
          <div className="row">
              <div className="col-md-4">
              <NumberFormat thousandSeparator={true} value={fixedChargeHour}
                                    onValueChange={(e) => {
                                      setFixedChargeHour(parseFloat(e.value));
                                    }}
                                    min={0}
                                    className="input-charge"/>
                <p style={{fontSize:"13px",textAlign:"center",marginRight:"26px"}}>Hours</p>

              </div>
              <div className="col-md-4" >
              <NumberFormat thousandSeparator={true} value={fixedChargeAmount}
                                    onValueChange={(e) => {
                                      setFixedChargeAmount(parseFloat(e.value));
                                    }}
                                    min={0}
                                    className="input-charge"/>
                <p style={{fontSize:"13px",textAlign:"center",marginRight:"26px"}}>Charge Amount</p>
              </div>
            </div>
        </div>

        <div style={{marginLeft:"-12px"}}>
          <label>Hourly Charge</label>
          <div className="row">
          <div className="col-md-4"  >
              <NumberFormat thousandSeparator={true} value={hourlyCharge}
                                    onValueChange={(e) => {
                                      setHourlyCharge(parseFloat(e.value));
                                    }}
                                    min={0}
                                    className="input-charge"/>
                <p style={{fontSize:"13px",textAlign:"center",marginRight:"26px"}}>Charge Amount</p>
              </div>
          </div>
        </div>
      </div>
    </div>
            


                    <div className="wrapper-charges">
                      <label className="label-checkbox">
                        Service Charge
                      </label>

                      <input type="number" value={serviceCharge}
                             onChange={(e) => {
                               setServiceCharge(parseFloat(e.target.value));
                             }}
                             min={0}
                             className="input-charge"/>

                      <p className="sub-title">%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal centered onHide={() => {
          setShowModal(false);
        }} show={showModal}>
          <Modal.Body>
            <div style={{display: "flex", flexDirection: "column"}}>
              <p style={{fontSize: "18px", color: "#222", padding: "20px 0px 0px 34px"}}>
                {"All future bookings will cancelled and refunded"}
              </p>
            </div>
            <div className="mt-4 text-right w-100">
              <AppButton onClick={() => {
                handleClick();
              }}
                         style={{
                           width: 100,
                           padding: 5,
                           backgroundColor: "#4bbb46",
                           color: "white",
                           marginRight: "0.6rem"
                         }}>
                {"Deactivate"}
              </AppButton>
              <AppButton onClick={() => {
                setShowModal(false)
              }}
                         style={{width: 100, padding: 5, backgroundColor: "#FEBE4D", color: "white",}}>
                {"Cancel"}
              </AppButton>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );

  function AvailableTime() {
    const weeks = weekList();

    return (
      <div>
        {weeks.map((w, i) => {
          return <div key={i}>{availableTimeItem(w, i)}</div>;
        })}
      </div>
    );
  }

  function availableTimeItem(weekName, weekNumber) {
    let selectedIndex = 0;
    let selectedAvailabilityWeek = amenityAvailability.find((item, i) => {

      if (item.weekNumber == weekNumber) {
        selectedIndex = i;
        return true;
      }

      return false;
    });

    return (
      <div className="wrapper-days">
        <div className="row no-gutters">
          <div style={{width: 125}}>
            <label className="label-checkbox">
              <Checkbox checked={selectedAvailabilityWeek ? true : false}
                        disabled={availabilityType == 0}
                        onChange={(e) => {
                          if (e.target.checked) {

                            let existing = [...amenityAvailability];

                            existing.push({
                              times: [
                                {
                                  startDate: moment(9, "HH"),
                                  endDate: moment(9, "HH").add("1", "h"),
                                },
                              ],
                              weekNumber: weekNumber,
                            });

                            setAmenityAvailability(existing);
                          } else {
                            //remove
                            let existing = [...amenityAvailability];

                            let filtered = existing.filter(
                              (item) => {
                                return (
                                  item.weekNumber != weekNumber
                                );
                              }
                            );

                            setAmenityAvailability(filtered);
                          }
                        }}/>
              {weekName}
            </label>
          </div>
          <div className="col">
            {selectedAvailabilityWeek?.times?.map((date, i) => {
              return (
                <div className="availability-time-wrap" key={i}>
                  <div className="row ">
                    <div className="col">
                      <RangePicker value={[date.startDate, date.endDate,]}
                                   clearIcon={null}
                                   format={"HH:mm"}
                                   className={"time-picker"}
                                   minuteStep={60}
                                   onChange={(val: any) => {
                                     if (val) {
                                       changeDate(val, selectedAvailabilityWeek!, selectedIndex, i);
                                     }
                                   }}
                      />
                    </div>

                    <div className="col-auto">
                      <button
                        onClick={() => {
                          let existingAvailable = [
                            ...amenityAvailability,
                          ];

                          let filter = [] as any[];

                          existingAvailable.forEach(
                            (itemAvailable) => {
                              if (itemAvailable == selectedAvailabilityWeek) {
                                let filteredTimes = itemAvailable.times.filter(
                                  (t, tIndex) => {
                                    return (tIndex != i);
                                  }) as any;

                                itemAvailable.times = filteredTimes;

                                filter.push(itemAvailable);
                              } else {
                                filter.push(itemAvailable);
                              }
                            }
                          );

                          setAmenityAvailability(filter);
                        }} className="btn-time-remove">
                        <i className="fas fa-times"/>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="col-auto ml-2">
            <button className="add-button"
                    onClick={() => {
                      let existing = [...amenityAvailability];

                      let selectedExisting = existing.find((x) => {
                        return x.weekNumber == weekNumber;
                      });

                      selectedExisting?.times.push({
                        startDate: moment(9 + selectedExisting.times.length, "HH"),
                        endDate: moment(9 + 1 + selectedExisting.times.length, "HH"),
                      });

                      setAmenityAvailability(existing);
                    }}>
              +
            </button>
          </div>
        </div>
      </div>
    );
  }

  function changeDate(val: moment.Moment[], selectedAvailabilityWeek: AmenityAvailability,
                      selectedAvailabilityWeekIndex: number, timesIndex: number) {

    selectedAvailabilityWeek!.times[timesIndex].startDate = val[0];
    selectedAvailabilityWeek!.times[timesIndex].endDate = val[1];

    var existing = [...amenityAvailability];
    existing[selectedAvailabilityWeekIndex] = selectedAvailabilityWeek as any;

    setAmenityAvailability(existing);
  }

  function handleInactive() {
    if (!isActive) {
      setShowModal(true)
    } else {
      handleClick()
    }
  }

  async function handleClick() {
    

    setIsLoading(true);

    // image upload
    let mainImageUploaded = mainImage;
    let otherImagesUploaded = [] as ImageState[];

    if (!mainImage) {
      showNotification("Validation Error", "Main Image Required!", "error");
      setIsLoading(false);
      return;
    }

    if(fixedChargeHour > 0 && fixedChargeAmount === 0){
      showNotification("Validation Error","Fixed Charge Amount is required when Fixed Charge Hour is set","error")
      setIsLoading(false);
      return;
    }

    try {
      if (mainImage.isBase64) {
        let url = await firebaseServices.uploadDoc(mainImage.url);
        mainImageUploaded = {isBase64: false, url: url};
      }

      for await (const otherImage of otherImages) {
        let url = otherImage.url;

        if (otherImage.isBase64) {
          url = await firebaseServices.uploadDoc(otherImage.url);
        }

        otherImagesUploaded.push({isBase64: false, url: url});
      }

      let result = (await apiService.addAmenity({
        id: id,
        name: name,
        bookingType: bookingType,
        description: description,
        image: mainImageUploaded,
        otherImages: otherImagesUploaded,
        minBookingTimeMinute: minBooking * 60,
        maxBookingTimeMinute: maxBooking * 60,
        capacity: maxOccupancy,
        feePerMinute: hourlyCharge / 60,
        fixedChargeHour:fixedChargeHour,
        fixedChargeAmount:fixedChargeAmount,
        depositFee: depositFee,
        serviceCharge: serviceCharge,
        availability: amenityAvailability,
        isActive: isActive,
        amenityAvailabilityStatus: availabilityType,
      })) as any;

      result = result.data;

      history.push(`/facility/add/${id}`);
    } catch (error) {
      handleErrors(error);
    }

    setIsLoading(false);
  }
}
