import React, {useContext, useEffect, useState} from "react";
import Papa from "papaparse";
import ApiService from "../../../services/ApiService";
import AppButton from "../../../components/AppButton/AppButton";
import {AdminSelectedCondoIdContext} from "../../../store/Store";
import readXlsxFile from 'read-excel-file'
import {handleErrors} from "../../../services/HelperMethods";

 function Bulk() {

    const [selectedCondoId] = useContext(AdminSelectedCondoIdContext)
    const apiService = new ApiService();
    const [data, setData] = useState([] as any);
    const [error, setError] = useState("");
    const [file, setFile] = useState("" as any);
    const [analyzeResult, setAnalyzeResult] = useState(null as any);
    const [dataToUpload, setDataToUpload] = useState(null as any);
    const [loading, setLoading] = useState(null as any);

    useEffect(() => {
        if (data.length > 0) {
            upload();
        }
    }, [data])

    function upload() {
        setLoading(true);
        try {
            apiService.ResidentBulkUpload(dataToUpload).then((result) => {
                let data = result.data;
                setAnalyzeResult(null);
                setDataToUpload(null);
            })
        } catch (err) {
        }
        setLoading(false);
    }

    const handleFileChange = (e) => {
        setError("");
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            // const fileExtension = inputFile?.type.split("/")[1];
            // if (!allowedExtensions.includes(fileExtension)) {
            //     setError("Please input a csv file");
            //     return;
            // }
            setFile(inputFile);
        }
    };

    const handleParse = async () => {
        setLoading(true);
        if (!file) return;
        var data = {
            buildingId: selectedCondoId,
            BulkUploadUsers: [] as any[]
        }
        const rows = await readXlsxFile(file);
        rows.shift();
        rows.forEach(d => {
            data.BulkUploadUsers.push({
                firstName: d[0],
                lastName: d[1],
                phoneNumber: d[2].toString() + d[3],
                email: d[4],
                doorNumber: d[5],
                floor: d[6],
                bedrooms: d[9],
                apartmentType: d[7],
                squareFeet: d[8],
                location: d[10]
            })
        })
        try {
            var result = await apiService.ResidentBulkUploadAnalyze(data)
            result = result.data;
            setAnalyzeResult(result)
            setDataToUpload(data);
        } catch (error) {
            handleErrors(error);
        }

        setLoading(false);


    };

    if (!selectedCondoId) {
        return <div>
            <div className="text-secondary">Please select a condominium</div>
        </div>
    }
    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="row align-items-center">


                        <div className="col">
                            Bulk Upload Users

                        </div>
                        <div className="col-auto">
                            <AppButton onClick={() => {
                                window.open("https://liwe.s3.ap-southeast-1.amazonaws.com/AdminUploadTemplate.xlsx", "_blank")
                            }}>Download Template</AppButton>
                        </div>
                    </div>
                </div>
                <div className="card-body">

                    {analyzeResult ? <div>

                            <h2>Total new users: {analyzeResult.totalNewUsers}</h2>
                            <h2>Total new apartments: {analyzeResult.totalNewApartments}</h2>
                            <h2>Total updated users: {analyzeResult.totalUpdateUsers}</h2>
                            <h2>Total updated apartments: {analyzeResult.totalUpdatedApartments}</h2>

                        </div> :
                        <div>
                            <label htmlFor="csvInput" style={{display: "block"}}>
                                Enter CSV File
                            </label>
                            <input onChange={handleFileChange} id="csvInput" name="file" type="File"/>
                        </div>
                    }
                </div>

                <div className="row mt-5 justify-content-end">
                    {analyzeResult ?

                        <div className="col-auto">
                            <AppButton isLoading={loading} onClick={upload}>Confirm and Upload</AppButton>
                        </div>

                        :
                        <div className="col-auto">
                            <AppButton isLoading={loading} onClick={handleParse}>Analyze</AppButton>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
};
export default Bulk;