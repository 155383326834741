import { useContext, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import AppButton from "../../../components/AppButton/AppButton";
import ApiService from "../../../services/ApiService";
import {
    handleErrors,
    showNotification,
} from "../../../services/HelperMethods";
import { AdminSelectedCondoIdContext } from "../../../store/Store";

export default function () {
    const [selectedCondoId] = useContext(AdminSelectedCondoIdContext);
    const apiService = new ApiService();
    const [isLoading, setIsLoading] = useState(false);
    const [apartment, setApartment] = useState({
        floor: "",
        doorNumber: "",
        type: "",
        bedrooms: 0 as string | number,
        location: "",
        totalSqFt: 0 as string | number,
    });
    const [apartments, setApartments] = useState([] as any[]);

    useEffect(() => {
        console.log(selectedCondoId);

        if (selectedCondoId > 0) {
            getApartments();
        }
    }, [selectedCondoId]);

    function addApartments() {
        setIsLoading(true);
        let data = {
            buildingId: selectedCondoId,
            apartments: [apartment],
        };
        apiService
            .addApartmentsAdmin(data)
            .then((result) => {
                let data = result.data;
                if (data?.isSuccessful) {
                    showNotification(
                        "Apartments Added Successfully",
                        "Apartments Added Successfully",
                        "success"
                    );
                    setApartment(
                        {
                            floor: "",
                            doorNumber: "",
                            type: "",
                            bedrooms: 0,
                            location: "",
                            totalSqFt: 0,
                        },
                    );
                } else {
                    showNotification(
                        "Error Adding Apartments",
                        "Error Adding Apartments",
                        "error"
                    );
                }
                setIsLoading(false);
            })
            .catch((err) => {
                handleErrors(err);
                setIsLoading(false);
            });

    }

    if (!selectedCondoId) {
        return <div>
            <div className="text-secondary">Please select a condominium</div>
        </div>
    }

    return (
        <div>
            <div>
                <div className="card">
                    <div className="card-header">Add new apartment</div>
                    <div className="card-body">
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Floor</label>
                            <input
                                className="form-control"
                                type="text"
                                style={{ maxWidth: "14rem" }}
                                placeholder="Floor"
                                onChange={(e) => {
                                    setApartment({ ...apartment, floor: e.target.value });
                                }}
                            />
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Door Number</label>
                            <input
                                className="form-control"
                                type="text"
                                style={{ maxWidth: "14rem" }}
                                placeholder="Door Number"
                                onChange={(e) => {
                                    setApartment({ ...apartment, doorNumber: e.target.value });
                                }}
                            />
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Type</label>
                            <input
                                className="form-control"
                                type="text"
                                style={{ maxWidth: "14rem" }}
                                placeholder="Type"
                                onChange={(e) => {
                                    setApartment({ ...apartment, type: e.target.value });
                                }}
                            />
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Number of Bedrooms</label>
                            <input
                                className="form-control"
                                type="text"
                                style={{ maxWidth: "14rem" }}
                                placeholder="Number of Bedrooms"
                                onChange={(e) => {
                                    setApartment({ ...apartment, bedrooms: e.target.value });
                                }}
                            />
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Location</label>
                            <input
                                className="form-control"
                                type="text"
                                style={{ maxWidth: "14rem" }}
                                placeholder="Location"
                                onChange={(e) => {
                                    setApartment({ ...apartment, location: e.target.value });
                                }}
                            />
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Square Feet</label>
                            <input
                                className="form-control"
                                type="text"
                                style={{ maxWidth: "14rem" }}
                                placeholder="Square Feet"
                                onChange={(e) => {
                                    setApartment({ ...apartment, totalSqFt: e.target.value });
                                }}
                            />
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-2 col-form-label" />
                            <div className="row">
                                <div className="col-auto">
                                    <AppButton isLoading={isLoading} onClick={() => { addApartments() }}>
                                        Save Apartments
                                    </AppButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Table responsive="lg" borderless={true}>
                    <thead>
                        <tr>
                            <th>Floor</th>
                            <th>Door Number</th>
                            <th>Number of Bedrooms</th>
                            <th>Location</th>
                            <th>Square Feet</th>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {apartments?.map((apartment, index) => {
                            return (
                                <tr>
                                    <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            style={{ maxWidth: "14rem" }}
                                            placeholder="Floor"
                                            value={apartment.floor}
                                            onChange={(e) => {
                                                apartment.floor = e.target.value;
                                                setApartments([...apartments]);
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            style={{ maxWidth: "14rem" }}
                                            placeholder="Door Number"
                                            value={apartment.doorNumber}
                                            onChange={(e) => {
                                                apartment.doorNumber = e.target.value;
                                                setApartments([...apartments]);
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            style={{ maxWidth: "14rem" }}
                                            placeholder="Number of Bedrooms"
                                            value={apartment.bedrooms}
                                            onChange={(e) => {
                                                apartment.bedrooms = parseInt(e.target.value);
                                                setApartments([...apartments]);
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            style={{ maxWidth: "14rem" }}
                                            placeholder="Location"
                                            value={apartment.location}
                                            onChange={(e) => {
                                                apartment.location = e.target.value;
                                                setApartments([...apartments]);
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <input
                                            className="form-control"
                                            type="text"
                                            style={{ maxWidth: "14rem" }}
                                            placeholder="Square Feet"
                                            value={apartment.totalSqFt}
                                            onChange={(e) => {
                                                apartment.totalSqFt = parseFloat(e.target.value);
                                                setApartments([...apartments]);
                                            }}
                                        />
                                    </td>

                                    <td>
                                        <AppButton
                                            style={{
                                                maxWidth: "4rem",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                                marginRight: "0.8rem",
                                            }}
                                            onClick={() => {
                                                edit(
                                                    apartment?.id,
                                                    apartment?.floor,
                                                    apartment?.doorNumber,
                                                    apartment?.type,
                                                    apartment?.bedrooms,
                                                    apartment?.location,
                                                    apartment?.totalSqFt
                                                );
                                            }}
                                        >
                                            <FaSave />
                                        </AppButton>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );

    function edit(
        apartmentId,
        floor,
        doorNumber,
        type,
        bedrooms,
        location,
        totalSqFt
    ) {
        setIsLoading(true);

        let data = {
            buildingId: selectedCondoId,
            apartmentId: apartmentId,
            floor: floor,
            doorNumber: doorNumber,
            type: type,
            bedrooms: bedrooms,
            location: location,
            totalSqFt: totalSqFt,
        };

        apiService
            .updateApartmentAdmin(data)
            .then((result) => {
                let data = result.data;
                console.log(data);

                if (data?.isSuccessful) {
                    showNotification(
                        "Apartment Updated Successfully",
                        "Apartment Updated Successfully",
                        "success"
                    );
                    getApartments();
                } else {
                    showNotification(
                        "Error Updating Apartment",
                        "Error Updating Apartment",
                        "error"
                    );
                }
            })
            .catch((err) => {
                handleErrors(err);
            });

        setIsLoading(false);
    }

    function getApartments() {
        setIsLoading(true);
        apiService
            .getApartmentsAdmin(selectedCondoId)
            .then((result) => {
                let data = result.data;

                if (data?.isSuccessful) {
                    var tempApartments = data?.apartments;

                    tempApartments.sort(function (a, b) {
                        if (a.doorNumber < b.doorNumber) {
                            return -1;
                        }
                        if (a.doorNumber > b.doorNumber) {
                            return 1;
                        }
                        return 0;
                    });

                    tempApartments.sort(function (a, b) {
                        return a.floor - b.floor;
                    });

                    setApartments(tempApartments);
                }
            })
            .catch((err) => {
                handleErrors(err);
            });

        setIsLoading(false);
    }
}
