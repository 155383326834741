import React, { createContext, useState } from "react";

export const UserDetailsContext = createContext([] as any[]);
export const AdminSelectedCondoIdContext = createContext([] as any[]);

export default function Store({ children }) {
    const [user, setUser] = useState(null);
    const [adminSelectedCondoId, setAdminSelectedCondoId] = useState(0);
    return (
        <UserDetailsContext.Provider value={[user, setUser]}>
            <AdminSelectedCondoIdContext.Provider value={[adminSelectedCondoId, setAdminSelectedCondoId]}>
                {children}
            </AdminSelectedCondoIdContext.Provider>
        </UserDetailsContext.Provider>
    );
}
