import { useContext, useEffect, useRef, useState } from "react"
import AppButton from "../../../components/AppButton/AppButton";
import ApiService from "../../../services/ApiService";
import ordinalSuffixOf, {
    getUserType,
    handleErrors,
    showNotification,
} from "../../../services/HelperMethods";
import { AdminSelectedCondoIdContext } from "../../../store/Store";
import DropDown from "../../../assets/images/icons/dropdown.svg";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

import "./AdminUser.scss"
import moment from "moment";

export default function () {
    const [selectedCondoId] = useContext(AdminSelectedCondoIdContext)
    const apiService = new ApiService();
    const [users, setUsers] = useState([] as any);
    const [apartments, setApartments] = useState([] as any);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [userType, setUserType] = useState(getUserType(0));
    const [apartmentId, setApartmentId] = useState(null as any);
    const [userBuildingData, setUserBuildingData] = useState([] as any);
    const [userId, setUserId] = useState(0);
    const [userBuildingId, setUserBuildingId] = useState(0);
    const [status, setStatus] = useState(0);


    useEffect(() => {
        if (selectedCondoId) {
            // getUsers();
            getApartments();
            getUserBuildings();
        }
    }, [selectedCondoId])

    async function getUserBuildings() {
        try {
            setIsLoading(true);
            let result = await apiService.getUsersBuildingsByBuildingId(selectedCondoId);
            setUserBuildingData(result.data.userData)

            setIsLoading(false);
        } catch (error) {
            handleErrors(error);
            setIsLoading(false);
        }
    }

    function getApartments() {
        setIsLoading(true);
        apiService
            .getApartmentsAdmin(selectedCondoId)
            .then((result) => {
                let data = result.data;

                if (data?.isSuccessful) {
                    var tempApartments = data?.apartments;
                    setApartments(tempApartments);

                }
            })
            .catch((err) => {
                handleErrors(err);
            });

        setIsLoading(false);
    }

    async function getUsers() {
        try {
            let result = await apiService.getUsersAdmin(selectedCondoId);
            setUsers(result.data.users);
            console.log('users', result.data.users);
        } catch (error) {
            handleErrors(error);
        }
    }
    
    async function getUserStatus(userId){
        try{
            let data = {
                UserId: userId
            }
            let result = await apiService.getUserStatus(data);
            setStatus(result.data.userStatus);
        }catch(error){
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function addUser() {
        try {
            setIsLoading(true);
            let data = {
                buildingId: selectedCondoId,
                userType,
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                apartmentId: apartmentId,
            }
            await apiService.addUsersAdmin(data);
            await getUserBuildings();
            clearData();

        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function updateUserBuilding() {
        try {
            setIsLoading(true);
            let data = {
                userBuildingId: userBuildingId,
                apartmentId: apartmentId,
                userType,
            }
            await apiService.updateUsersBuilding(data);
            await getUserBuildings();
            showNotification("Updated", "Apartment Updated", "success");
            clearData();

        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function disableUserBuilding() {
        try {
            setIsLoading(true);
            await apiService.disableUsersBuildingsByBuildingId(userBuildingId);
            await getUserBuildings();
            showNotification("Updated", "Apartment Disabled", "success");
            setUserId(0);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function enableUserBuilding() {
        try {
            setIsLoading(true);
            await apiService.enableUsersBuildingsByBuildingId(userBuildingId);
            await getUserBuildings();
            showNotification("Updated", "Apartment Enabled", "success");
            setUserId(0);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function removeUser(id: any) {
        try {
            setIsLoading(true);
            await apiService.removeUsersAdmin(id);
            await getUserBuildings();
            clearData();
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function deleteUser(userId: number){
        try{
            setIsLoading(true);
            let data = {
                UserId: userId
            }

            let result = await apiService.changeStatus(data)
            setStatus(result.data.userStatus)
        }catch(error){
            handleErrors(error)
        }
        setIsLoading(false);
    }

    if (!selectedCondoId) {
        return <div>
            <div className="text-secondary">Please select a condominium</div>
        </div>
    }

    async function updateUser() {
        try {
            setIsLoading(true);
            let data = {
                email: email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneNumber,
                id: userId,
            }
            await apiService.updateUsersAdmin(data);
            await getUserBuildings();
            clearData();
            showNotification("Updated", "User Updated", "success");
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    async function sendCredentialEmail(buildingUserId) {
        try {
            setIsLoading(true);
            await apiService.sendCredentialEmailAdmin(buildingUserId);
            await getUserBuildings();
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    function clearData() {
        setApartmentId("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setUserId(0);
        setUserBuildingId(0);
    }

    return <div>
        <div>
            <div className="card">
                <div className="card-header">
                    {!userBuildingId ? userId ? "Update User" : "Add User" : "Update Apartment/ Role"}
                </div>
                <div className="card-body">
                    {(!userId || userBuildingId)
                        ? <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Role</label>
                            <div>
                                <select className="form-control" value={userType} onChange={(e) => {
                                    setUserType(e.target.value);
                                    setApartmentId("");
                                }}>
                                    <option value={"EntityAdmin"}>Entity Admin</option>
                                    <option value={"Tenant"}>Tenant</option>
                                    <option value={"ApartmentOwner"}>Apartment Owner</option>
                                    <option value={"Child"}>Child</option>
                                    <option value={"User"}>User</option>
                                    <option value={"Guard"}>Guard</option>
                                    <option value={"Maintenance"}>Maintenance</option>
                                    <option value={"Cleaning"}>Cleaning</option>
                                    <option value={"SystemAdmin"}>System Admin</option>
                                    <option value={"Accountant"}>Accountant</option>
                                    <option value={"HelpDesk"}>Help Desk</option>
                                    <option value={"Engineer"}>Engineer</option>
                                    <option value={"Administration"}>Administration</option>
                                    <option value={"RecreationalOfficer"}>Recreational Officer</option>
                                    <option value={"CleaningSupervisor"}>Cleaning Supervisor</option>
                                </select>
                            </div>
                        </div>
                        : <></>}
                    {
                        !userBuildingId
                            ? <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Email</label>
                                <div>
                                    <input className="form-control" type="text" value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }} placeholder="Email" />
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        !userBuildingId
                            ? <div className="form-group row">
                                <label className="col-sm-2 col-form-label">First Name</label>
                                <div>
                                    <input className="form-control" type="text" value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                        }} placeholder="First Name" />
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        !userBuildingId
                            ? <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Last Name</label>
                                <div>
                                    <input className="form-control" type="text" value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                        }} placeholder="Last Name" />
                                </div>
                            </div>
                            : <></>
                    }
                    {
                        !userBuildingId
                            ? <div className="form-group row">
                                <label className="col-sm-2 col-form-label">Phone Number</label>
                                <div>
                                    <input className="form-control" type="text" value={phoneNumber}
                                        onChange={(e) => {
                                            setPhoneNumber(e.target.value);
                                        }} placeholder="Phone Number" />
                                </div>
                            </div>
                            : <></>
                    }

                    {(!userId || userBuildingId) && userType != "Guard" && userType != "Maintenance" && userType != "Staff"
                        && userType != "Cleaning" && userType != "SystemAdmin" && userType != "EntityAdmin"
                        && userType != "Accountant"
                        && userType != "HelpDesk"
                        && userType != "CleaningSupervisor"
                        && userType != "RecreationalOfficer"
                        && userType != "Engineer"

                        ?
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label">Apartment</label>
                            <div>
                                <select value={apartmentId} onChange={(e) => {
                                    setApartmentId(e.target.value)
                                }} className="form-control">
                                    <option value={''}>
                                        Select an apartment
                                    </option>
                                    {apartments.map((val, i) => {
                                        return (
                                            <option value={val.id} key={i}>
                                                {val.location}, {ordinalSuffixOf(val.floor)} Floor, {val.doorNumber}
                                            </option>
                                        )
                                    })}

                                </select>
                            </div>
                        </div> : <></>
                    }
                    <div className="form-group row">
                        <div className="col-sm-2 col-form-label" />
                        <div className="row">
                            {userId > 0 &&
                                <div className="col-auto">
                                    <AppButton variant="danger" isLoading={isLoading} onClick={() => {
                                        removeUser(userId)
                                    }}>Delete user record</AppButton>
                                    
                                    
                                </div>
                            }
                            <div className="col-auto">
                                <AppButton isLoading={isLoading} onClick={() => {
                                    !userBuildingId ? userId ? updateUser() : addUser() : updateUserBuilding()
                                }}>{!userBuildingId ? userId ? "Update User" : "Save User" : "Update Apartment"}</AppButton>
                            </div>
                            <div className="col-auto">
                            {
                                status == 6 ? 
                                    <AppButton variant="danger" isLoading={isLoading} onClick={() => {
                                        //removeUser(userId)
                                        deleteUser(userId)
                                    }}>Remove by request</AppButton> : 
                                    <></>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="admin-table-container">
                <div className="table">
                    <div className="header">
                        <div className="header-row large">
                            <span className="text">Name</span>
                        </div>
                        <div className="header-row medium">
                            <span className="text">Email</span>
                        </div>
                        <div className="header-row medium">
                            <span className="text">Contact</span>
                        </div>
                        <div className="header-row medium" />
                        <div className="header-row medium" />
                    </div>
                    <div className="data">
                        {userBuildingData?.map((item, i) => {
                            return (
                                <Accordion key={i}>
                                    <AccordionSummary
                                        classes={{ content: "accordion-summery" }}
                                        expandIcon={<img style={{ width: "30px", height: "30px", marginTop: "6px", cursor: "pointer" }} src={DropDown} alt="Arrow" />}
                                        aria-controls="panel1a-content"
                                        id="panel1bh-header"
                                    >

                                        <div className="data-row pending-padding">
                                            <div className="cell text large">{item.firstName} {item.lastName}</div>
                                            <div className="cell text medium">{item.email}</div>
                                            <div className="cell text medium">{item.contact}</div>
                                            <div className="cell text medium space">
                                                <AppButton isLoading={isLoading}
                                                    onClick={() => {

                                                        
                                                        setUserId(item.userId);
                                                        setFirstName(item.firstName);
                                                        setLastName(item.lastName);
                                                        // setUserType(getUserType(item.activeBuildingUser?.userType));
                                                        // setApartmentId(item.apartmentId);
                                                        setEmail(item.email);
                                                        setPhoneNumber(item.contact);
                                                        setUserBuildingId(0);
                                                        getUserStatus(item.userId);
                                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                                    }}>
                                                    Update
                                                </AppButton>
                                            </div>
                                            <div className="cell text medium space">
                                                <AppButton isLoading={isLoading}
                                                    style={{ backgroundColor: item.isCredentialEmailSent ? 'green' : 'red' }}
                                                    onClick={() => {
                                                        sendCredentialEmail(item.activeUserBuildingId);
                                                    }}>Send Email</AppButton>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <div className="accordion-data">
                                            <div className="header_">
                                                <div className="medium header-text">
                                                    Location
                                                </div>
                                                <div className="medium header-text">
                                                    Role
                                                </div>
                                                <div className="medium header-text">
                                                    Last Seen
                                                </div>
                                                <div className="medium" />
                                            </div>
                                            {item?.userAppartments?.map((building) => {
                                                return (
                                                    <div className="apartment-data">

                                                        <div className="medium body-text">
                                                            {building.location ? building.location + " " + building.floor + " " + building.doorNumber : "-"}
                                                        </div>

                                                        <div className="medium body-text">
                                                            {getUserType(building.role)}
                                                        </div>
                                                        <div className="medium body-text">
                                                            <span className="badge badge-secondary">
                                                              {building.lastSeen ? moment.utc(building.lastSeen).local().format('lll') : "-"}
                                                            </span>
                                                        </div>
                                                        <div className="medium flex-end">
                                                            <AppButton style={{ width: "100px", marginRight: "10px" }} isLoading={isLoading}
                                                                onClick={() => {
                                                                    setUserType(getUserType(building.role));
                                                                    setApartmentId(building.apartmentId);
                                                                    setUserBuildingId(building.userBuildingId);
                                                                    setUserId(0);

                                                                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

                                                                }}>
                                                                Update
                                                            </AppButton>
                                                            {building.isActive
                                                                ? <AppButton style={{ width: "100px", backgroundColor: "red" }} isLoading={isLoading}
                                                                    onClick={() => {
                                                                        setUserBuildingId(building.userBuildingId);
                                                                        disableUserBuilding();
                                                                    }}>
                                                                    Deactivate
                                                                </AppButton>
                                                                : <AppButton style={{ width: "100px", backgroundColor: "ge" }} isLoading={isLoading}
                                                                    onClick={() => {
                                                                        setUserBuildingId(building.userBuildingId);
                                                                        enableUserBuilding();
                                                                    }}>
                                                                    Activate
                                                                </AppButton>}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>)
                        })}
                    </div>
                </div>

            </div>

        </div>
    </div >
}