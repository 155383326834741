import React, { Fragment, useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { useParams } from "react-router-dom";
import ordinalSuffixOf, {
  showNotification,
} from "../../services/HelperMethods";
import ApiService from "../../services/ApiService";
import moment from "moment";

export default function QrGenerator() {
  const { inviteCode } = useParams() as any;
  const apiService = new ApiService();
  const [invCode, setInvCode] = useState();
  const [visitor, setVisitor] = useState() as any;
  let data: any;

  async function getVisitorDetails() {
    let visitorDetails = await apiService.getVisitorQrData(data.invCode);
    setVisitor(visitorDetails.data.visitor);
  }

  useEffect(() => {
    try {
      data = JSON.parse(window.atob(inviteCode));
      setInvCode(data.invCode);
      getVisitorDetails();
    } catch (error) {
      showNotification(
        "Invalid Invitation",
        "Invalid Invitation, Please generate QR code from your LIWE mobile app",
        "error",
        { autoClose: false }
      );
    }
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        textAlign: "center",
        backgroundColor: "#d0d0d0",
      }}
    >
      <div>
        <div className="logo mb-5">
          <img src="/images/logo.png" />
        </div>
        <div
          style={{
            padding: 15,
            backgroundColor: "white",
            boxShadow: "1px 2px 3px gray",
            width: 350,
            margin: "0 auto",
            borderRadius: 5,
          }}
        >
          {invCode ? <QRCode size={300} value={invCode} /> : <Fragment />}
        </div>
        {visitor && (
          <div style={{ marginTop: 10 }}>
            <div>
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                {visitor?.name}
              </span>
            </div>
            <div>
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
              >
                {moment.utc(visitor?.arrivalDate).local().format("ll")}
              </span>
            </div>
            {visitor?.location && (
              <div>
                <span style={{ fontWeight: "bold", fontSize: 18 }}>
                  Location - {visitor?.location}
                </span>
              </div>
            )}
            <div>
              <span style={{ fontWeight: "bold", fontSize: 18 }}>
                {ordinalSuffixOf(visitor?.floor)} Floor, {visitor?.doorNumber}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
